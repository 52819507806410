import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Suspense, useEffect } from 'react'
import './App.css';
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles/'
import { useGlobal } from './contexts/GlobalContext';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import './resources/Font - Satoshi/css/satoshi.css'
import Home from './main/components/Home';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loading from './misc/Loading';
// import About from './misc/About';
import Contacto from './misc/Contacto';
import Editor from './misc/Editor';
import Interlinear from './bible/data/interlinear/Interlinear';
import InterlinearB from './bible/data/interlinear/InterlinearB';
import InterlinearC from './bible/data/interlinear/InterlinearC';
import Test from './misc/ScrapeGoogleTranslatorTwo';
import ScrapeGoogleTranslatorOne from './misc/ScrapeGoogleTranslatorOne';
import DocumentPicker from './documents/core/DocumentPicker';
import DocumentEditor from './documents/core/DocumentEditor';
import ArticlePage from './documents/ArticlePage';
import EditorWrapper from './documents/core/EditorWrapper';
import About from './main/components/About';
import Articulos from './blog/components/Articulos';
import Iglesia from './blog/components/posts/iglesia';
import GuiaDeDios from './blog/components/posts/guia_de_dios';
import HiperboleDidactica from './blog/components/posts/hiperbole_didactica';
import DardosEnemigo from './blog/components/posts/dardos_enemigo';
import FeVsObras from './blog/components/posts/fe_vs_obras';
import Septuagint from './blog/components/posts/Septuagint';
import Amargura from './blog/components/posts/amargura';
import Rencor from './blog/components/posts/rencor';
import RaizDeAmargura from './blog/components/posts/raiz_de_amargura';
import AmistadConElMundo from './blog/components/posts/amistad_con_el_mundo';
// import DisplayJSON from './cross_references/test_one/ParseLogosTCB';
// import ParseLogosTCB from './cross_references/test_one/ParseLogosTCB';
// import { BibleUploader } from './cross_references/BibleUploader';
// import CrossReferenceSearch from './cross_references/test_one/CrossReferenceSearch';
// import ParseThompsonES from './cross_references/test_one/ParseThompsonES';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { get_parsed_art } from './timeline/resources/data/top_art_beckchris_normal';
const SobreLaArmonia = React.lazy(() => import('./blog/components/posts/sobre_la_armonia _c'));
const Fe = React.lazy(() => import('./blog/components/posts/fe'));
const CalendarCrucifixion = React.lazy(() => import('./calendar_crucifixion/CalendarCrucifixion'));
const Timeline = React.lazy(() => import('./timeline/Timeline'));
const Harmony = React.lazy(() => import('./harmony/components/Harmony'));
const Formatter = React.lazy(() => import('./harmony/components/Formatter'));
const JesusChronology = React.lazy(() => import('./jesus_chronology/JesusChronology'));

function App() {

  const { mui_theme, theme, uppercase, bigFont } = useGlobal()

  const Fallback = () => {
    return (
      <div
        style={{
          // width: '100%',
          // height: '100%',
          // textAlign: 
          margin: 'auto'
        }}
      >
        Descargando
      </div>
    )
  }

  return (
    <ThemeProvider theme={mui_theme}>
      <HelmetProvider>
        <div className="App">
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/test" element={<Test />} />
              {/* <Route path="/homea" element={<Home />} /> */}
              {/* <Route path="/homeb" element={<HomeB />} /> */}
              {/* <Route path="/homec" element={<HomeC />} /> */}
              <Route path="/linea" element={<Timeline />} />
              <Route path="/sinopsis" element={<Harmony />} />
              <Route path="/armoniaformatter" element={<Formatter />} />
              <Route path='/cronologiaevangelios' element={<JesusChronology />} />
              <Route path='/calendariocrucifixion' element={<CalendarCrucifixion />} />
              {/* <Route path="/bloga" element={<BlogTestA />} /> */}
              {/* <Route path="/blogb" element={<BlogTestB />} /> */}
              {/* <Route path="/blogc" element={<BlogTestC />} /> */}
              {/* <Route path="/blogd" element={<BlogTestD />} /> */}
              {/* <Route path="/bloge" element={<BlogTestE />} /> */}
              {/* <Route path="/blogf" element={<BlogTestF />} /> */}
              {/* <Route path="/blogg" element={<BlogTestG />} /> */}
              {/* <Route path="/blogh" element={<BlogTestH />} /> */}
              {/* <Route path="/blogi" element={<BlogTestI />} /> */}
              <Route path="/que-es-una-armonia" element={<SobreLaArmonia />} />
              <Route path="/que-es-una-sinopsis" element={<SobreLaArmonia />} /> 
              <Route path="/fe" element={<Fe />} />
              <Route path="/amargura" element={<Amargura />} />
              <Route path="/raiz-de-amargura" element={<RaizDeAmargura />} />
              <Route path="/rencor" element={<Rencor />} />
              <Route path="/amistad-con-el-mundo" element={<AmistadConElMundo />} />
              <Route path="/iglesia" element={<Iglesia />} />
              <Route path="/guia-de-dios" element={<GuiaDeDios />} />
              <Route path="/hiperbole-didactica" element={<HiperboleDidactica />} />
              <Route path="/dardos-enemigo" element={<DardosEnemigo />} />
              <Route path="/fe-vs-obras" element={<FeVsObras />} />
              <Route path="/septuaginta" element={<Septuagint />} />
              <Route path='/editor/:id' element={<Editor />} />
              <Route path='/interlinear' element={<Interlinear />} />
              <Route path='/interlinearb' element={<InterlinearB />} />
              <Route path='/interlinearc' element={<InterlinearC />} />
              <Route path='/test' element={<Test />} />
              {/* <Route path='/testTCB' element={<ParseLogosTCB />} />
              <Route path='/referencias' element={<CrossReferenceSearch />} /> */}
              {/* <Route path='/ParseThompsonCommentary' element={<ParseThompsonES />} /> */}
              {/* <Route path='/BibleUploader' element={<BibleUploader />} /> */}
              {/* <Route path='/referencias' element={<Box />} /> */}

              <Route path="/edit" element={<DocumentPicker />} />
              <Route path="/edit/:document_id/:branch_id/:version_id" element={<EditorWrapper />} />
              <Route path="/articulo/:doc_id" element={<ArticlePage />} />
              <Route path="/articulos" element={<Articulos />} />
              {/* temp */}
              <Route path="/sortthing" element={<Box><Button onClick={get_parsed_art}>CLICK</Button></Box>} />
            </Routes>
          </Suspense>
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
