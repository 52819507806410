import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import './raiz_de_amargura.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'

const badge = 'Introducción'
const title = 'Ren\u00ADcor'
// const html_title = <span>Septua<span className='shy'></span>ginta</span>
const subtitle = 'Características generales y respuestas bíblicas.'

export default function Rencor() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            concepto: {
                content: <><a href='https://concepto.de/resentimiento/'>https://concepto.de/resentimiento/</a>.</>,
                // type: 'comment',
            },
            efesios: {
                content: <>También Efesios 4:26-27.</>,
                // type: 'comment',
            },
            balance: {
                content: <>Resolver un conflicto implica encontrar un balance entre perdonar y protegernos a nosotros mismos (y a los demás). En la mayoría de los casos, hace falta limpiar nuestro corazón de sentimientos negativos y también hacer algunos cambios.</>,
                type: 'comment',
            },
            rencor: {
                content: <><a href='https://dle.rae.es/rencor'>https://dle.rae.es/rencor</a></>,
                // type: 'comment',
            }
        }

        return (
            <Box
                className='ArticleBody'
            >
                <Container>
                    <Typography>
                        El rencor es <Ref d={fn.rencor}>"resentimiento arraigado y tenaz"</Ref>. A su vez, el resentimiento es un disgusto o desagrado hacia alguien por considerarlo responsable (directo o indirecto) de algún daño, dolor u ofensa sufrido en el pasado. Este malestar puede variar en intensidad, ya sea como una molestia pasajera o como una aversión permanente:
                    </Typography>
                    <Typography>
                        <Quote>
                            <Ref d={fn.concepto}>
                            El término “resentimiento” tiene raíces latinas, es una palabra formada fundamentalmente por las voces re– (prefijo latino de repetición o intensidad) y el verbo sentire (“sentir”), por lo que puede entenderse como “volver a sentir lo vivido”. Es decir, que una persona resentida experimenta en el presente el daño o la ofensa sufridos, como si acabaran de ocurrir; dicho de otro modo, no puede o no quiere dejar en el pasado lo ocurrido.
                            </Ref>
                        </Quote>
                    </Typography>
                    <Typography>
                        Cuando hay rencor hacia alguien es posible que sea difícil tratarlos bien. Por ejemplo, los hermanos de José “no podían hablarle bien” <BibleRef parentheses>Génesis 37:4</BibleRef>. 
                    </Typography>
                    <Typography>
                        Muchas veces el resentimiento abre la puerta al pecado. Después de que Caín resintiera a Abel, Dios le dijo:
                    </Typography>
                    <Typography>
                        <Quote>
                            <Ref d={fn.efesios}>¿Por qué te has ensañado, y por qué ha decaído tu semblante? Si bien hicieres, ¿no serás enaltecido? y si no hicieres bien, el pecado está a la puerta; con todo esto, a ti será su deseo, y tú te enseñorearás de él. <BibleRef parentheses>Génesis 4:6-7</BibleRef></Ref>
                        </Quote>
                    </Typography>
                    <Typography>
                    Cuando hay rencor, a veces, sin darse cuenta, una persona pierde la alegría que tenía antes, o tal vez ya no siente la misma tranquilidad y seguridad. El trato con los demás también se puede deteriorar cuando estos sentimientos nos llevan a tratar a otros con menos paciencia, más brusquedad y desconfianza.
                    </Typography>
                    <Typography variant='h2'>
                        Perdón
                    </Typography>
                    <Typography>
                        Perdonar es dejar atrás los sentimientos negativos hacia alguien. Podría decirse que es lo opuesto al rencor.
                    </Typography>
                    <Typography>
                        Perdonar no siempre significa olvidar lo sucedido y/o que todo siga como antes<Ref d={fn.balance}/>. A veces lo ocurrido nos hace dar cuenta de que una situación no es saludable y debemos cambiarla. Mantener la paz también es saber evitar problemas en el futuro.
                    </Typography>
                    <Typography>
                        Además de superar los sentimientos que intoxican la mente, hay más razones para perdonar:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Debemos tratar a los demás como queremos que nos traten.</span>
                            </Typography>
                            <Typography>
                                <Quote>
                                    Y como queréis que hagan los hombres con vosotros, así también haced vosotros con ellos. <BibleRef parentheses>Lucas 6:31</BibleRef>
                                </Quote>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Nosotros tampoco somos perfectos.</span> Así como recibimos perdón también debemos estar dispuestos a darlo.
                            </Typography>
                            <Typography>
                                <Quote>
                                    Si decimos que no tenemos pecado, nos engañamos a nosotros mismos, y la verdad no está en nosotros. <BibleRef parentheses>1 Juan 1:8</BibleRef>
                                </Quote>
                            </Typography>
                            <Typography>
                                <Quote>
                                    […] ustedes estaban muertos en sus transgresiones. Sin embargo, Dios nos dio vida en unión con Cristo, al perdonarnos todos los pecados. <BibleRef reference='Col 2:13' ver='NVI' parentheses>Col 2:13, NVI</BibleRef>
                                </Quote>
                            </Typography>
                            <Typography>
                                <Quote>
                                    ¿Por qué miras la paja que está en el ojo de tu hermano, y no echas de ver la viga que está en tu propio ojo? ¿O cómo puedes decir a tu hermano: Hermano, déjame sacar la paja que está en tu ojo, no mirando tú la viga que está en el ojo tuyo? Hipócrita, saca primero la viga de tu propio ojo, y entonces verás bien para sacar la paja que está en el ojo de tu hermano. <BibleRef parentheses>Lucas 6:41-42</BibleRef>
                                </Quote>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Es un mandamiento de los Apóstoles.</span> P. ej: <BibleRef>Rom. 12:18; 1 Pe. 3:8-12; Ef. 4:26-27</BibleRef>.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>Es un mandamiento de Jesús.</span>
                            </Typography>
                            <Typography>
                                <Quote>
                                    No juzguéis, y no seréis juzgados; no condenéis, y no seréis condenados; perdonad, y seréis perdonados. Dad, y se os dará; medida buena, apretada, remecida y rebosando darán en vuestro regazo; porque con la misma medida con que medís, os volverán a medir. <BibleRef parentheses>Mt. 7.1-5</BibleRef>
                                </Quote>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>Jesús perdona y nos llama a ser sus discípulos.</span>
                            </Typography>
                            <Typography>
                                <Quote>
                                    Y cuando llegaron al lugar llamado de la Calavera, le crucificaron allí, y a los malhechores, uno a la derecha y otro a la izquierda. Y Jesús decía: Padre, perdónalos, porque no saben lo que hacen. Y repartieron entre sí sus vestidos, echando suertes. <BibleRef parentheses>Lucas 23:33-34</BibleRef>
                                </Quote>
                            </Typography>
                            <Typography>
                                <Quote>
                                    Llevad mi yugo sobre vosotros, y aprended de mí, que soy manso y humilde de corazón; y hallaréis descanso para vuestras almas; <BibleRef parentheses>Mateo 11:29</BibleRef>
                                </Quote>
                            </Typography>
                            <Typography>
                                En este texto vemos que no hace falta que nos pidan perdón para perdonar, sino que deberíamos hacerlo tan pronto como podamos.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>Necesitamos perdonar para que Dios nos perdone.</span>
                            </Typography>
                            <Typography>
                                <Quote>
                                    Porque si perdonan a otros sus ofensas, también los perdonará a ustedes su Padre celestial. Pero si no perdonan a otros sus ofensas, tampoco su Padre perdonará a ustedes las suyas. <BibleRef parentheses reference='Mateo 6:14-15' ver='NVI'>Mateo 6:14-15, NVI</BibleRef>
                                </Quote>
                            </Typography>
                            <Typography>
                                La parábola de Jesús en Mateo 18:23-35 hace un gran trabajo explicando por qué el perdón de Dios es una razón para perdonar y no seremos perdonados si no perdonamos.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        Jesús además de enseñar sobre perdón va un paso más allá y enseña que debemos amar a quienes nos lastiman:
                    </Typography>
                    <Typography>
                        <Quote>
                        <span className='VN'>43</span> Oísteis que fue dicho: Amarás a tu prójimo, y aborrecerás a tu enemigo. <span className='VN'>44</span> Pero yo os digo: Amad a vuestros enemigos, bendecid a los que os maldicen, haced bien a los que os aborrecen, y orad por los que os ultrajan y os persiguen; <span className='VN'>45</span> para que seáis hijos de vuestro Padre que está en los cielos, que hace salir su sol sobre malos y buenos, y que hace llover sobre justos e injustos. <span className='VN'>46</span> Porque si amáis a los que os aman, ¿qué recompensa tendréis? ¿No hacen también lo mismo los publicanos? <span className='VN'>47</span> Y si saludáis a vuestros hermanos solamente, ¿qué hacéis de más? ¿No hacen también así los gentiles? <span className='VN'>48</span> Sed, pues, vosotros perfectos, como vuestro Padre que está en los cielos es perfecto.
                        </Quote>
                    </Typography>
                    <Typography variant='h2'>
                        Conclusión
                    </Typography>
                    <Typography>
                        Es necesario perdonar.
                    </Typography>
                    <Typography>
                        Este es un ejemplo de oración que podemos hacer para perdonar:
                    </Typography>
                    </Container>
                    <Box
                        sx={{
                            '& .MuiTypography-root': {
                                textAlign: 'center',
                                p: '0px !important',
                                // color: '#33A56D',
                                color: '#4A885D',
                                fontVariationSettings: '"wght" 400',
                                fontStyle: 'italic',
                            },
                            // background: '#E6E8FF',
                            background: '#F3F4F0',
                            paddingBottom: '24px',
                            paddingTop: '21px',
                            marginBottom: '0.6em',
                        }}
                    >
                        <Typography>
                            Dios, en este momento,
                        </Typography>
                        <Typography>
                            perdono a [nombre] por [mencionar lo que hizo].
                        </Typography>
                        <Typography>
                            Perdona también mis pecados.
                        </Typography>
                        <Typography>
                            En el nombre de Jesús, amén.
                        </Typography>
                    </Box>
                    <Container>
                    <Typography>
                        Es posible que debamos hacer esta oración varias veces y/o en distintas ocasiones hasta sentir que realmente hubo un cambio en nosotros.
                    </Typography>
                    <Typography>
                        Aprender a perdonar tan pronto como nos hemos ofendido es una habilidad que puede proteger nuestra salud y felicidad, nuestras relaciones, y hasta nuestra salvación:
                    </Typography>
                    <Typography>
                        <Quote>
                            Airaos, pero no pequéis; no se ponga el sol sobre vuestro enojo, ni deis lugar al diablo. <BibleRef parentheses>Efesios 4:26-27</BibleRef>
                        </Quote>
                    </Typography>
                    <Notas footnotes={fn} />
                    </Container>
                    <Box
                        sx={{
                            backgroundColor: '#002A38',
                            pt: '21px',
                            pb: '32px',
                            px: '8px',
                            '& .MuiTypography-root': {
                                color: '#F7CFA7',
                                fontStyle: 'italic',
                                textAlign: 'center',
                            },
                        }}
                    >
                        <Typography sx={{
                            fontSize: '60px !important',
                            pb: '0px !important',
                            pt: '0px !important',
                            mt: '0px !important',
                            mb: '-20px !important',
                        }}>
                            “”
                        </Typography>
                        <Typography>
                            Perdono lo imperdonable en otros
                        </Typography>
                        <Typography>
                            Porque Dios perdonó lo imperdonable en mi
                        </Typography>
                        <Typography>
                            — C.S. Lewis
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: {
                                    us: '100%',
                                    md: '50%',
                                },
                                flexGrow: 1,
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: '#F7EEE1',
                                    pt: '21px',
                                    pb: '32px',
                                    px: '8px',
                                    flexGrow: 1,
                                    '& .MuiTypography-root': {
                                        color: '#3B4034',
                                        fontStyle: 'italic',
                                        textAlign: 'center',
                                    },
                                }}
                            >
                                <Typography sx={{
                                    fontSize: '60px !important',
                                    pb: '0px !important',
                                    pt: '0px !important',
                                    mt: '0px !important',
                                    mb: '-20px !important',
                                }}>
                                    “”
                                </Typography>
                                <Typography>
                                    Perdonar es liberar a un prisionero y descubrir que el prisionero eras tú.
                                </Typography>
                                <Typography>
                                    — Lewis B. Smedes
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                maxWidth: {
                                    us: '100%',
                                    md: '50%',
                                },
                                flexGrow: 1,
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: '#BAA345',
                                    pt: '21px',
                                    pb: '32px',
                                    px: '8px',
                                    flexGrow: 1,
                                    '& .MuiTypography-root': {
                                        color: '#F8F3ED',
                                        fontStyle: 'italic',
                                        textAlign: 'center',
                                    },
                                }}
                            >
                                <Typography sx={{
                                    fontSize: '60px !important',
                                    pb: '0px !important',
                                    pt: '0px !important',
                                    mt: '0px !important',
                                    mb: '-20px !important',
                                }}>
                                    “”
                                </Typography>
                                <Typography>
                                    A veces, el perdonar al que lo causó se siente más doloroso que la herida que se sufrió. Y sin embargo, no hay paz sin perdón.
                                </Typography>
                                <Typography>
                                    — Marianne Williamson
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                <Container>
                    <Author />
                </Container>
            </Box>
        )
    }

    // https://psicologiaymente.com/social/frases-de-perdon


    const body_css = {
        title: {
            fontSize: {
                us: '9rem',
                // md: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '0px',
                md: '1px',
            },
        },

    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Recia' display_font='Recia' badge={badge} title={title} subtitle={subtitle} Body={<Body />} other={body_css} classes={{wrapper: 'SoftBlogColors'}} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}
