import Box from '@mui/material/Box'
import React, { ReactElement } from 'react'
import { morphgnt_sbl_james } from '../bible/data/interlinear/greek text/sblgnt-morphgnt/80-Jas-morphgnt'
import { Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { tsk_one } from './TSK_one'
import Container from '@mui/material/Container'

export default function Test() {
    return (
        <Container>
            {
                tsk_one.map((e) => {
                    return (
                        <Box>
                            <Typography variant='h3'>
                                {
                                    e.ref
                                }
                            </Typography>
                            <div dangerouslySetInnerHTML={{__html: e.comment}} />
                        </Box>
                    )
                })
            }
        </Container>
    )
}