import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'

{/* <Box component='aside' sx={{
                            float: 'right',
                            background: '#FFFCEF',
                            color: '#6322EB',
                            borderTop: '2px solid #6322EB',
                            borderBottom: '2px solid #6322EB',
                            p: '8px',
                            px: '32px',
                            pb: '20px',
                            m: '8px',
                            mr: '12px',
                            fontStyle: 'italic',
                            textAlign: 'center',
                            fontSize: '1.1em',
                        }}>
                            <span
                                style={{
                                    fontSize: '50px',
                                    textAlign: 'center',
                                    marginBottom: '-16px',
                                    display: 'block',
                                    height: '50px',
                                    fontStyle: 'normal',
                                }}
                            >
                                        “”
                            </span>
                            Jesús conoció<br/>la amargura
                        </Box> */}

const badge = 'Introducción'
const title = 'Amar\u00ADgura'
// \u00AD
// const html_title = <span>Septua<span className='shy'></span>ginta</span>
const subtitle = 'Características generales y respuestas bíblicas.'

export default function Amargura() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            resumen: {
                content: <>El resumen de la historia de Noemí fue inspirado por el artículo “Naomi: An Example of Steadfast Faith” de Courtney Jacob en https://groundworkonline.com/blog/naomi-an-example-of-steadfast-faith</>,
                // type: 'comment',
            },
            sufrimiento: {
                content: <>El ejemplo del sufrimiento durante la vida humana de Jesús es el más cercano a nuestro sufrimiento, pero en realidad el Padre y el Espíritu también sufren, por ejemplo cuando son despreciados por nosotros como vemos en el caso de Oseas y Gomer (Padre) o en Efesios 4:30 (Espíritu)</>,
                // type: 'comment',
            },
        }

        return (
            <Box
                className='ArticleBody'
            >
                <Container>
                    <Typography>
                        La amargura es un profundo sentimiento de disgusto y tristeza. Muchas veces es parte de la vida; sentirnos así no es pecado ni motivo de vergüenza. Cuando una situación nos causa amargura a veces no encontramos soluciones ni consuelo.
                    </Typography>
                    <Typography>
                        Jesús también conoció la amargura: fue traicionado por un amigo, injuriado, juzgado injustamente, y crucificado. Tal vez nos preguntemos “¿Dónde está Dios cuando sufrimos?” o “Si Dios es bueno, ¿Por qué hay tanto sufrimiento?”. Realmente no tenemos todas las respuestas, pero podemos estar seguros de que Él conoce nuestro dolor y está cerca nuestro. Nuestro Dios no está solamente en los días buenos sino también en los malos.
                    </Typography>
                    <Typography variant='h2'>
                        Origen
                    </Typography>
                    <Typography>
                        La amargura puede tener diversos orígenes:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Pérdida</span>: experimentar pérdidas significativas, como la muerte de un ser querido, la pérdida de un empleo o la pérdida de una relación.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Decepción</span>: expectativas no cumplidas, ya sea en relaciones interpersonales, laborales o en otras áreas de la vida.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Comparación</span>: compararse con los demás puede causar inseguridad, envidia y celos.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Maltrato</span>: críticas, desprecios, injusticias, desinterés, descuido, mentiras, decepciones.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        De a poco y sin darnos cuenta, cuando sucede alguna de estas cosas a veces empieza a acumularse amargura en el corazón.
                    </Typography>
                    <Typography>
                        La amargura puede crecer hasta cubrirlo todo. Muchas veces absorbe los pensamientos y las fuerzas de la persona y destruye su vida espiritual. No permite correr la bendición que Dios tiene preparada para ella.
                    </Typography>
                    <Typography>
                        Pero también es posible salir adelante en los momentos difíciles y lograr un corazón sano.
                    </Typography>
                    <Typography variant='h2'>
                        Ejemplos bíblicos
                    </Typography>
                    <Typography variant='h3'>
                        Historia de Noemí
                    </Typography>
                    <Typography>
                        Noemí y su familia debieron viajar a otro país buscando sobrevivir a una gran escasez que hubo en su tierra. Siendo extranjera en esta nueva tierra, su esposo fallece y queda sola con sus dos hijos. En algún momento sus hijos encuentran esposas en Moab. Probablemente esto fue una decepción para Rut, ya que tenían prohibido por Dios casarse con ellas <BibleRef parentheses>Dt 23:3-6, 7:1-4</BibleRef>. Además, todo esto sugería que nunca volverían a su lugar de origen. Pero las pérdidas de Noemí no habían terminado, porque sus dos hijos fallecieron antes de darle nietos.
                    </Typography>
                    <Typography>
                        Noemí se encontraba sin familia, sustento económico, ni futuro. El colapso de su vida no sucedió de un día al otro sino en el curso de años de dolor, decepciones, y pérdidas.
                    </Typography>
                    <Typography>
                        Noemí no disimula cómo se siente. Cuando vuelve a su casa les dice a sus viejos conocidos:
                    </Typography>
                    <Typography>
                        <Quote>
                        <Ref bible>
                            No me llaméis Noemí, sino llamadme Mara; porque en grande amargura me ha puesto el Todopoderoso. Yo me fui llena, pero Jehová me ha vuelto con las manos vacías. ¿Por qué me llamaréis Noemí, ya que Jehová ha dado testimonio contra mí, y el Todopoderoso me ha afligido? </Ref><BibleRef parentheses>Rut 1:20-21</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        Desde el punto de vista de Noemí el sentimiento de amargura parecía definir su nueva identidad y por eso decide llamarse Amargura (o, en hebreo, Mara).
                    </Typography>
                    <Typography>
                        Aunque Noemí tenía tanta tristeza y se sentía vacía, ella no deja de ser una persona amable (podemos verlo en su trato a Rut) ni se enoja con Dios. Podemos observar que la Biblia no la llama Amargura sino que sigue llamándola Noemí. A pesar de sentirse agobiada por la situación al final Noemí no permite que su amargura eche raíces y defina su identidad. Después de muchos años de decepciones y disgustos logra tener un corazón sano y una actitud saludable.
                    </Typography>
                    <Typography>
                        A pesar de las circunstancias Noemí logra ser económicamente independiente y forma parte de una nueva familia. Muchos años después, Jesús sería descendiente de esta familia.
                    </Typography>
                    <Typography>
                        Esta historia es un ejemplo de cómo la amargura puede ser parte inevitable de la vida pero es posible evitar que eche raíces y mantener un corazón sano.<Ref d={fn.resumen}/>
                    </Typography>
                    <Typography variant='h3'>
                        Historia de Ana
                    </Typography>
                    <Typography>
                        Encontramos la historia de Ana en <BibleRef>1 Samuel 1:1-2:21</BibleRef>.
                    </Typography>
                    <Typography>
                        Consideremos las siguientes preguntas:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>¿Cuál era el principal motivo de tristeza para Ana?</span>
                            </Typography>
                            <Typography>
                                Probablemente el hecho de no poder tener hijos.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>¿Qué maltratos recibió?</span>
                            </Typography>
                            <Typography>
                                Fue tratada de forma hiriente por Penina que se burlaba, por su esposo que no la entendía, y por Elí que creyó que estaba borracha. Según vemos lo que más la afectaba (al punto de no comer) era lo que hacía Penina. Su esposo y Elí no actuaron con malas intenciones (de hecho su esposo la amaba y la trataba bien como vemos en <BibleRef>1 Sam 1:5</BibleRef>), pero seguramente haya sido doloroso de todas formas.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>¿Vemos alguna señal de que guardara rencor hacia alguien?</span>
                            </Typography>
                            <Typography>
                                No.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>¿Cuál fue el final de su historia?</span>
                            </Typography>
                            <Typography>
                                Pudo tener hijos. El primero se quedó en el templo y los otros tres con ellos en su casa.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Si en algún momento difícil de su historia hubieras podido hablar con Ana… ¿Qué le hubieras dicho?</span>
                            </Typography>
                            <Typography>
                                Esta pregunta también se puede hacer respecto a Noemí.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h2'>
                        Conclusión
                    </Typography>
                    <Typography>
                        Es admirable cuando una persona logra salir adelante a pesar de la amargura. Dios quiere sanar nuestro corazón y consolarnos. Él nos da propósito y desea acompañarnos hasta que logremos estar sanos y tener paz.
                    </Typography>
                    <Typography>
                        Además, tenemos una ayuda extra que es el Espíritu Santo:
                    </Typography>
                    <Typography>
                        <Quote>
                            <Ref bible>Pero les digo la verdad: Les conviene que me vaya porque, si no lo hago, el Consolador no vendrá a ustedes; en cambio, si me voy, se lo enviaré a ustedes.</Ref> <BibleRef parentheses>Juan 16:7</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                        <Ref bible>Y yo le pediré al Padre, y él les dará otro Consolador para que los acompañe siempre.</Ref> <BibleRef parentheses>Juan 14:16</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                    Él nos acompaña para que podamos experimentar lo mismo que David cuando escribe <Ref bible>“Cuando en mí la angustia iba en aumento, tu consuelo llenaba mi alma de alegría” </Ref><BibleRef parentheses>Salmo 94:19</BibleRef>.
                    </Typography>
                    <Typography>
                    Pablo hace un gran resumen:
                    </Typography>
                    <Typography>
                        <Quote>
                        <Ref bible>Alabado sea el Dios y Padre de nuestro Señor Jesucristo, Padre misericordioso y Dios de toda consolación, quien nos consuela en todas nuestras tribulaciones para que, con el mismo consuelo que de Dios hemos recibido, también nosotros podamos consolar a todos los que sufren </Ref><BibleRef parentheses>2 Corintios 1:3-4</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        La Biblia nos despide con una extraordinaria promesa de lo que nos espera en la eternidad:
                    </Typography>
                    <Typography>
                        <Quote>
                        <Ref bible>Ya no sufrirán hambre ni sed.
No los abatirá el sol ni ningún calor abrasador.
Porque el Cordero que está en el trono los pastoreará
y los guiará a fuentes de agua viva;
y Dios les enjugará toda lágrima de sus ojos. <BibleRef parentheses>Apocalipsis 7:16-17</BibleRef></Ref>

                        </Quote>
                    </Typography>
                    <Typography variant='h2'>
                        Preguntas Frecuentes
                    </Typography>
                    <Typography variant='h4'>
                        ¿Cuál es la relación con la “raíz de amargura”?
                    </Typography>
                    <Typography>
                        La desobediencia a Dios es como una raíz de la que luego brotará amargura. Para más detalles ver el <a href='raiz-de-amargura'>artículo sobre Raíz de Amargura</a>.
                    </Typography>
                    <Typography variant='h4'>
                        ¿Si Dios es bueno por qué hay tanto sufrimiento?
                    </Typography>
                    <Typography>
                        Esto suele llamarse "el problema del mal". En breve, (1) <b>Por la libertad.</b> Si alguien tiene una sola opción no es verdaderamente libre. Por lo tanto, para que los seres humanos sean libres es necesario que haya una alternativa a Dios y sus planes. La alternativa que existe es Satanás y sus planes. Desde el Jardín del Edén la humanidad decidió darle lugar a Satanás y sus planes, transformando la creación perfecta de Dios en un lugar difícil de habitar. (2) <b>Porque Dios también está dispuesto a sufrir.</b> Dios no es ajeno al sufrimiento humano sino que se hizo de carne y hueso y sufrió de forma inimaginable en la cruz.<Ref d={fn.sufrimiento}/> (3) <b>Porque el sufrimiento no es permanente.</b> Dios promete un futuro donde no hay sufrimiento y donde la muerte fue derrotada. Esto también indica que a Dios no le agrada el sufrimiento. <b>(Conclusión)</b> Este resumen deja fuera otras razones, pero cualquiera de estas es suficiente por si sola — y las tres juntas aún más. Dicho esto, no hay forma de que una explicación, por más convincente que sea, nos haga sentir menos amargura cuando sucede algo doloroso. Aún así, Dios tiene otras formas de consolarnos en momentos difíciles.
                    </Typography>
                    <Typography variant='h4'>
                        ¿Si Dios es bueno por qué sentimos amargura?
                    </Typography>
                    <Typography>
                        El dolor emocional es un mal necesario. Es un sistema de alarma. Hay personas que no sienten dolor físico y por ello se terminan lastimando (por ejemplo, no se dan cuenta cuando tocaron algo que los está quemando, o cuando se están mordiendo la lengua). De la misma forma, si no existiera el dolor emocional nos dañaríamos a nosotros mismos y quienes nos rodean. Este sistema de alarma nos ayuda a saber cuando debemos actuar, a valorar lo que tenemos, y a aprender de nuestros errores.
                    </Typography>
                    <Notas footnotes={fn} />
                </Container>
                    <Box
                        sx={{
                            backgroundColor: '#013533',
                            pt: '21px',
                            pb: '32px',
                            px: '8px',
                            '& .MuiTypography-root': {
                                color: '#DFC797',
                                fontStyle: 'italic',
                                textAlign: 'center',
                            },
                        }}
                    >
                        <Typography sx={{
                            fontSize: '60px !important',
                            pb: '0px !important',
                            pt: '0px !important',
                            mt: '0px !important',
                            mb: '-20px !important',
                        }}>
                            “”
                        </Typography>
                        <Typography>
                            Dios nos susurra a través del placer.
                        </Typography>
                        <Typography>
                            Nos habla a través de nuestra conciencia.
                        </Typography>
                        <Typography>
                            Y nos grita a través del dolor.
                        </Typography>
                        <Typography>
                            — C.S. Lewis
                        </Typography>
                    </Box>
                <Container>
                    <Author />
                </Container>
            </Box>
        )
    }

    const body_css = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '0px',
                md: '1px',
            },
        },
        second_header: {
            boxShadow: '#EEF9F4 -683px 0px 0px',
            // color: 'pink !important'
        }
    }

    const body_css_larken = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                xs: '-0.01em',
                md: '0em',
            },
        },
        body: {
            '& .NegativeListLi': {
                pb: '0px',
            },
            '& .YellowList': {
                pb: '0px',
            },
        },
        // other: {
        //     second_header: {
        //         boxShadow: '#DDDD6B -683px 0px 0px',
        //     }
        // }
    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Recia' display_font='Recia' badge={badge} title={title} subtitle={subtitle} Body={<Body />} other={body_css} classes={{wrapper: 'SoftBlogColors'}} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}
