import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

const Outline = () => {
  const [headings, setHeadings] = useState([]);
  const [toolbarHeight, setToolbarHeight] = useState(0);

  useEffect(() => {
    // Calculate toolbar height
    const toolbar = document.querySelector(".MuiToolbar-root"); // Assuming MUI AppBar with Toolbar
    if (toolbar) {
      const updateToolbarHeight = () => setToolbarHeight(toolbar.offsetHeight);
      updateToolbarHeight();

      // Recalculate on window resize
      window.addEventListener("resize", updateToolbarHeight);
      return () => window.removeEventListener("resize", updateToolbarHeight);
    }
  }, []);

  useEffect(() => {
    const blogContent = document.querySelector(".ArticleBody");
    if (blogContent) {
      const headingElements = blogContent.querySelectorAll("h1, h2, h3, h4, h5, h6");
      const parsedHeadings = Array.from(headingElements).map((heading) => ({
        level: parseInt(heading.tagName.substring(1)), // Extract level from the tag
        text: heading.innerText,
        id: heading.id || heading.innerText.toLowerCase().replace(/\s+/g, "-"), // Generate an ID if missing
      }));
  
      // Add IDs to headings if not already present
      parsedHeadings.forEach((heading) => {
        Array.from(headingElements).forEach((el) => {
          console.log('GOT', heading)
          if (el.innerText.trim() === heading.text.trim() && !el.id) {
            el.id = heading.id; // Set the ID on the actual element
          }
        });
      });
  
      setHeadings(parsedHeadings);
    }
  }, []);
  
  const handleLinkClick = (e, id) => {
    e.preventDefault();
    const target = document.getElementById(id);
    if (target) {
      const targetPosition = target.offsetTop;
      const offsetPosition = targetPosition - toolbarHeight;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  return (
    <nav style={{ margin: "0px 0" }}>
      <Typography variant="h2">Contenidos</Typography>
      <List
        sx={{
          p: '0px !important'
        }}
      >
        {/* {
          headings.map((heading) => (
            <ListItem
              key={heading.id}
              style={{ paddingLeft: `${heading.level * 8}px`
            }}>
              <ListItemButton component="a" href={`#${heading.id}`}>
                <ListItemText
                  primary={`${heading.text}`}
                  sx={{
                    '& .MuiTypography-root': {
                      pb: '0px',
                    }
                  }}
                />
              </ListItemButton>
            </ListItem>
        ))} */}
        {
          headings.map((heading) => (
            <ListItem
              key={heading.id}
              style={{ paddingLeft: `${(heading.level - 2) * 24}px`
            }}>
              {/* <ListItemButton component="a" href={`#${heading.id}`}> */}
              <Typography
                sx={{
                  pb: '0px !important'
                }}
              >
                <a
                  href={`#${heading.id}`}
                  onClick={(e) => handleLinkClick(e, heading.id)}
                >
                {/* <ListItemText
                  primary={`${heading.text}`}
                  sx={{
                    '& .MuiTypography-root': {
                      pb: '0px',
                    }
                  }}
                /> */}
                  {heading.text}
                </a>
              </Typography>
              {/* </ListItemButton> */}
            </ListItem>
        ))}
      </List>
    </nav>
  );
};

export default Outline;
