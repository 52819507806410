import Box from '@mui/material/Box'
import { PropsWithChildren } from 'react'

export default function Quote(props: PropsWithChildren<{}>) {
    const { children } = props
    return (
        <Box
            className='BlogQuote'
            sx={{
                pl: '0.7em',
                ml: 'calc(-3px - 0.7em)',
                borderLeft: `5px solid #3acfd5`,
                // borderImage: 'linear-gradient(to bottom, #3acfd5 0%, #3a4ed5 100%) 1',
                // borderImage: 'linear-gradient(to bottom, #3acfd5 0%, #515ada 100%) 1',
                // borderImage: 'linear-gradient(to bottom, #3acfd57f 0%, #3EBBD67f 100%) 1',
                borderImage: 'linear-gradient(to bottom, var(--quote-top) 0%, var(--quote-bottom) 100%) 1',
                // my: '3px',
                // fontStyle: 'italic',
                // fontFamily: 'Spline Sans Variable Italic',
                // fontSize: '.9em',
                lineHeight: '1.4',
                // my: '0.6em',
                // mt: '-0.3em',
                // si cambio esto se ven horribles los quotes en ul. Lo q hay que hacer es devolverle el espaciado a los párrafos en ol y asegurarme que la estructura de html esté correcta en otros archivos.
                // mb: '0.3em',
                // pb: '4px',
                // my: '0px',
            }}
        >
            { children }
        </Box>
    )
}