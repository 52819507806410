import { createTheme } from '@mui/material/styles'
import { iExtendedTheme } from '../contexts/GlobalContext'
import { mui_breakpoints, breakpoints, git_light_colors, fire_colors, chr_dev_colors } from './index'
// import Logo_Small_WhiteBackground_WhiteLetters from "../resources/Logo_Small_WhiteBackground_WhiteLetters.png"
// import Logo_Big_Black_Text_WhiteGlobe from '../resources/LightEverythingWhite.svg';

export const extended_blue: iExtendedTheme = {
    font_text: ['Spline Sans Variable', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'].join(','),
    font_display: ['Satoshi', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'].join(','),
    font_text_variable: ['Spline Sans Variable', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'].join(','),
    font_display_variable: ['Satoshi Variable', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'].join(','),
    colors: {
        primary: '#111a2b',
        secondary: '#0066ff',
        // lines: '#ececee',
        lines: '#80808024',
        text_primary: '#2a2a2a',
        text_secondary: '#383838',
        background_paper: '#fff',
        background_default: '#f2f2f2',
        blue: fire_colors.blue,
        blue_bg: fire_colors.blue_bg,
        lightblue: fire_colors.lightblue,
        lightblue_bg: fire_colors.lightblue_bg,
        green: fire_colors.green,
        green_solid: fire_colors.green,
        green_bg: '#f0fff4',
        orange: fire_colors.orange,
        orange_bg: fire_colors.orange_bg,
        red: '#cb2431',
        red_bg: '#ffeef0',
        pink: chr_dev_colors.pink_darkest,
        pink_bg: chr_dev_colors.pink_lighter,
        violet: '#5a32a4',
        violet_bg: '#f4f0ff',
        gray: fire_colors.gray,
        gray_bg: fire_colors.gray_bg,
    },
    // logo_small: Logo_Small_WhiteBackground_WhiteLetters,
    // logo_big: Logo_Big_Black_Text_WhiteGlobe,
    marker: {
        color: '#06f',
        background: '#0066ff0f',
    },
    // toolbar_height: '84px',
    toolbar_height: '100px',
    // o 90
}

const { font_text, font_display, font_display_variable, font_text_variable } = extended_blue

export const mui_blue = createTheme({
    breakpoints: {
        values: breakpoints,
    },
    shadows: [
        'none',
        '','','','','','','','','','','','','','','','','','','','','','',
        '0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075)',
        '0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075)',
    ],
    typography: {
        // fontFamily: ['Red Hat Text'].join(','),
        fontFamily: font_text_variable,
        htmlFontSize: 10,
        h1: {
            fontFamily: font_display_variable,
            fontFeatureSettings: "'ss03', 'ss02'",
            // lineBreak: 'anywhere',
            // '@supports (font-variation-settings: normal)': {
            //     fontFamily: 'Satoshi Variable',
            //     fontWeight: 300,
            //     fontVariationSettings: '"wght" 900',
            // },
        },
        h2: {
            fontFamily: font_display_variable,
            '@supports (font-variation-settings: normal)': {
                fontFamily: font_display_variable,
                fontVariationSettings: '"wght" 700',
            },
            fontWeight: 700,
            fontSize: '3.8rem',
            letterSpacing: '-0.025em',
            marginTop: '32px',
            // marginBottom: '32px',
            marginBottom: '24px',
            fontFeatureSettings: "'ss03', 'ss02'",
        },
        h3: {
            fontFamily: font_display_variable,
            '@supports (font-variation-settings: normal)': {
                fontFamily: font_display_variable,
                fontVariationSettings: '"wght" 700',
            },
            letterSpacing: '-1px',
            // fontWeight: 500,
            fontWeight: 700,
            fontSize: '3rem',
            fontFeatureSettings: "'ss03', 'ss02'",
            marginBottom: '18px',
            marginTop: '18px',
        },
        h4: {
            fontFamily: font_display_variable,
            fontFeatureSettings: "'ss03', 'ss02'",
            // letterSpacing: '-0.5px',
            fontWeight: 600,
            fontSize: '2.3rem',
            marginBottom: '10px',
            marginTop: '0px',
        },
        h5: {
            fontFamily: font_display_variable,
            fontFeatureSettings: "'ss03', 'ss02'",
        },
        h6: {
            fontFamily: font_display_variable,
            fontFeatureSettings: "'ss03', 'ss02'",
        },
        subtitle1: {
            fontFeatureSettings: "'ss03', 'ss02'",
            color: '#181818',
            opacity: 1,
        },
        body1: {
            '@supports (font-variation-settings: normal)': {
                fontVariationSettings: '"wght" 320',
                fontFeatureSettings: "'ss03', 'ss02'",
                // fontFeatureSettings: "'ss01'",
            },
            // paddingBottom: '0.5em',
            fontFamily: font_display_variable,
            // fontFeatureSettings: '"ss02"',
            // fontSize: '1.7rem',
            fontSize: '1.87rem',
            fontWeight: 400,
            [mui_breakpoints.breakpoints.down('md')]: {
                fontSize: '1.6rem'
            },
            // letterSpacing: '0.1px',
            color: extended_blue.colors.text_primary,
            // fontVariationSettings: '"wght" 370, "wdth" 105, "YTAS" 780, "YTDE" -240',
            // fontVariationSettings: '"wght" 420'
        },
        body2: {
            fontSize: '1.6rem',
            color: extended_blue.colors.text_secondary,
            fontFamily: font_text_variable,
            fontVariationSettings: '"wght" 400',
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: extended_blue.colors.primary,
            contrastText: '#fefefe'
        },
        secondary: {
            main: extended_blue.colors.secondary
        },
        text: {
            primary: '#2a2a2a',
            secondary: '#2a2a2a',
        },
        background: {
            default: extended_blue.colors.background_default,
            paper: extended_blue.colors.background_paper,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    '&.MuiPaper-root': {
                        backgroundColor: 'transparent',
                    //     borderLeft: 'none',
                    //     borderRight: 'none',
                    //     borderTop: 'none',
                    // borderBottom: extended_blue.colors.lines
                    }
                },
                
            },
        },
        MuiToolbar: {
            // styleOverrides: {
                // root: {
            //         backgroundColor: extended_blue.colors.primary,
            //         boxShadow: '0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075)',
        // },
    // },
    styleOverrides: {
        root: {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(7px)',
            height: extended_blue.toolbar_height,
            [mui_breakpoints.breakpoints.down('md')]: {
                height: '61px'
            },
                    // transition: 'backdrop-filter 1s linear !important',
                    // borderBottom: `1px solid ${extended_blue.colors.lines}`
        },
    },
},
        MuiPaper: {
            styleOverrides: {
                root: {
                },
                elevation0: {
                    border: 'none'
                },
                elevation1: {
                    border: 'none',
                    padding: '0px',
                    margin: '0px'
                },
                elevation23: {
                    backgroundColor: '#fff'
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: font_display_variable,
                    fontSize: '1.4rem',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    // borderRadius: '10px',
                    // borderRadius: '17px',
                    borderRadius: '11px',
                    minHeight: '51px'
                },
                contained: {
                    boxShadow: 'none'
                },
                outlined: {
                    border: `1px solid ${extended_blue.colors.lines}`,
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                //   color: "black",
                  marginBottom: '15px',
                  "&$focused": {
                    color: "black"
                  }
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // margin: '0px !important',
                    // flexGr
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    height: '51px',
                },
                notchedOutline: {
                    borderColor: extended_blue.colors.lines,
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                  borderRadius: '10px',
                },
                input: {
                    '&::placeholder': {
                        opacity: 0.8,
                    },
                },
                underline: {
                    "&::before": {
                        marginLeft: '10px',
                        marginRight: '10px',
                    },
                    "&::after": {
                        marginLeft: '10px',
                        marginRight: '10px'
                    }
                },
                multiline: {
                    margin: '0px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                },
                shrink: {
                    marginTop: '0px'
                },
                outlined: {
                    marginTop: '-3px'
                },
                filled: {
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '21px',
                    border: 'none'
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    // background: 'radial-gradient(circle, rgba(36,48,62,.5) 37%, rgba(12,17,22,.5) 100%)',
                    backdropFilter: 'blur(2px)',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: extended_blue.colors.lines
                },
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2.4rem',
                    color: 'inherit'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem'
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                },
                listbox: {
                    borderLeft: `1px solid ${extended_blue.colors.lines}`,
                    borderRight: `1px solid ${extended_blue.colors.lines}`,
                    borderBottom: `1px solid ${extended_blue.colors.lines}`,
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    boxShadow: 'none',
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    // fontSize: '1.7rem',
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    '& .MuiSwitch-thumb': {
                        backgroundColor: '#fff'
                    },
                    '& .Mui-checked': {
                        '& .MuiSwitch-thumb': {
                            backgroundColor: '#fff'
                        },
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    // color: 'white'
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 8px gray; 0px 0px 4px gray; 0px 0px 2px gray',
                    borderRadius: '8px',
                    '& .MuiTypography-body1': {
                        fontSize: '1.5rem'
                    }
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    // backgroundColor: '#000',
                    // '& ::hover': {
                    //     backgroundColor: '#000'
                    // }
                }
            }
        }
    },
})



// neuomorphic shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%), 0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%), 0 16px 16px hsl(0deg 0% 0% / 8%), 0px -3px 7px hsl(180deg 80% 100% / 60%)
// + 1px solid #ffffffe0
// with transparent background

// break the glass ts
// 1px 0px #141f33, 0.5px 0px #141f33