import { iNewTimelineCollection } from "../../iTimelineTwo";

const main_color = '#330A33';

export const top_art_beckchris_normal: iNewTimelineCollection = {
    id: {
        code: "top_art_beckchris",
        label: {
        },
    },
    settings: {
        position: {
            date: {
                start: {
                    value: '0'
                }
            },
        },
    },
    elements_class: {
        id: {
            label: {
                hide: true,
            }
        }
    },
    elements: [
        {
            id: {
                code: '8dknnl9d32d',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1512'
                    }
                },
                lane: 1,
                y_offset: -276,
            },
            image: {
                url: 'https://www.worldhistory.org/img/r/p/1500x1500/12580.jpg.webp?v=1722002533',
                position: {
                    height: 170,
                    width: 300,
                },
            },
            text_one: {
                content: ['Frescos de la Capilla Sixtina'],
            },
            text_two: {
                content: ['Miguel Ángel'],
            }
        },
        {
            id: {
                code: '8d9d32d',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1517'
                    }
                },
                lane: 1,
                y_offset: -97,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/04/mona_lisa.jpg?resize=419%2C621&ssl=1',
                position: {
                    height: 230,
                    width: 110,
                },
            },
            text_one: {
                content: ['Mona Lisa'],
            },
            text_two: {
                content: ['Leonardo da Vinci'],
            },
        },
        {
            id: {
                code: 'efDh82h',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1907'
                    }
                },
                lane: 1,
                y_offset: -29,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/les_demoiselles_davignon-2.jpg?resize=625%2C648&ssl=1',
                position: {
                    height: 200,
                    width: 86,
                },
            },
            text_one: {
                content: ['Les Demoiselles d’Avignon'],
            },
            text_two: {
                content: ['Pablo Picasso'],
            },
        },
        {
            id: {
                code: 'xa84fh',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1656'
                    }
                },
                lane: 1,
                y_offset: -446,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/las_meninas.jpg?resize=570%2C646&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Damas de honor'],
            },
            text_two: {
                content: ['Diego Velázquez'],
            },
        },
        {
            id: {
                code: 'ASJfes',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1937'
                    }
                },
                lane: 1,
                y_offset: -168,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/04/guernica.jpg?resize=625%2C234&ssl=1',
                position: {
                    height: 200,
                    width: 200,
                },
            },
            text_one: {
                content: ['Guernica'],
            },
            text_two: {
                content: ['Pablo Picasso'],
            },
        },
        {
            id: {
                code: 'xkjnaefhy',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1893'
                    }
                },
                lane: 1,
                y_offset: -188,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/04/the_scream.jpg?resize=503%2C623&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Grito'],
            },
            text_two: {
                content: ['Edvard Munch'],
            },
        },
        {
            id: {
                code: 'ad9ryrg',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1504'
                    }
                },
                lane: 1,
                y_offset: -608,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/michelangelo-david.jpg?resize=249%2C471&ssl=1',
                position: {
                    height: 216,
                    width: 100,
                },
            },
            text_one: {
                content: ['David'],
            },
            text_two: {
                content: ['Miguel Ángel'],
            },
        },
        {
            id: {
                code: '0y34b3g',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1889'
                    }
                },
                lane: 1,
                y_offset: -305,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/van_gogh_-_starry_night.jpg?resize=625%2C495&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Noche Estrellada'],
            },
            text_two: {
                content: ['Vincent Van Gogh'],
            },
        },
        {
            id: {
                code: 'cnaodicn',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1814'
                    }
                },
                lane: 1,
                y_offset: -80,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/third-of-may_francisco_de_goya.jpg?resize=625%2C482&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Tres de Mayo, 1808'],
            },
            text_two: {
                content: ['Francisco Goya'],
            },
        },
        // {
        //     id: {
        //         code: 'QC8hdc',
        //     },
    
        //     position: {
        //         type: 'date',
        //         date: {
        //             start: {
        //                 value: '1305'
        //             }
        //         },
        //         lane: 1,
        //         y_offset: -30,
        //     },
        //     image: {
        //         url: 'https://cdn3.iguzzini.com/getmedia/3290e3d4-9515-4c20-939d-8e8b0a97a801/                   Cappella_degli_Scroveg0ni_02?width=1200& height=800100,
                        //  100{
        //         width: 180,
        //     },
        //     text_one: {
        // contentte[xt: 'Frescos de la Capilla de los Scrovegni'],
        //         },
        // text_two         subtitle: {
        //             content: ['Giotto'],
        //         },
        // },
        {
            id: {
                code: 'JHBbfahdbOHBADF9',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1486'
                    }
                },
                lane: 1,
                y_offset: 3.3,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/birth-of-venus-2.jpg?resize=625%2C392&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Nacimiento de Venus'],
            },
            text_two: {
                content: ['Sandro Botticelli'],
            },
        },
        // {
        //     id: {
        //         code: 'JHBbfah',
        //     },
    
        //     position: {
        //         type: 'date',
        //         date: {
        //             start: {
        //                 value: '1505'
        //             }
        //         },
        //         lane: 1,
        //         y_offset: 150,
        //     },
        //     image: {
        //         url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/04/bosch.jpg?resize=625%2C334&ssl=1',
        //         position: {
        //         width: 400,
        //     },
        //     text_one: {
        // contentte[xt: 'Jardín de los Placeres Terrenales'],
        //         },
        // text_two         subtitle: {
        //             content: ['Hieronymous Bosch'],
        //         },
        // },
        // {
        //     id: {
        //         code: 'JHBbf4573ah',
        //     },
    
        //     position: {
        //         type: 'date',
        //         date: {
        //             start: {
        //                 value: '1516'
        //             }
        //         },
        //         lane: 1,
        //         y_offset: 230,
        //     },
        //     image: {
        //         url: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Chapel_of_Unterlinden_Museum_with_Isenheim_altarpiece.jpg',
        //         position: {
        //         width: 400,
        //     },
        //     text_one: {
        // contentte[xt: 'Retablo de Isenheim'],
        //         },
        // text_two         subtitle: {
        //             content: ['Matthias Grünewald'],
        //         },
        // },
        {
            id: {
                code: 'dkn',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1652'
                    }
                },
                lane: 1,
                y_offset: 264,
            },
            image: {
                url: 'https://www.learner.org/wp-content/uploads/2019/03/art-through-time-dreams-and-visions-work-098-The-Ecstasy-of-St.-Teresa.jpg',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Éxtasis de Santa Teresa'],
            },
            text_two: {
                content: ['Gian Lorenzo Bernini'],
            },
        },
        {
            id: {
                code: 'ad89uhdjADS3ah',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1886'
                    }
                },
                lane: 1,
                y_offset: 140,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/a_sunday_on_la_grande_jatte_georges_seurat.jpg?resize=625%2C420&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Tarde de domingo en la isla de la Grande Jatte'],
            },
            text_two: {
                content: ['Georges Seurat'],
            },
        },
        {
            id: {
                code: 'ad89uhjA9trdxcvb',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1819'
                    }
                },
                // lane: 1,
                y_offset: 68,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/05/gc3a9ricault_raft-of-the-medusa.jpg?resize=625%2C427&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['La Balsa de la Medusa'],
            },
            text_two: {
                content: ['Théodore Géricault'],
            },
        },
        {
            id: {
                code: 'ad89uhsdasdcvb',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1930'
                    }
                },
                lane: 1,
                y_offset: -50,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/05/wood_-_american_gothic.jpg?resize=516%2C626&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['American Gothic'],
            },
            text_two: {
                content: ['Grant Wood'],
            },
        },
        {
            id: {
                code: 'ad89sdasdsdcvb',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '-208'
                    }
                },
                lane: 1,
                y_offset: 329,
            },
            image: {
                url: 'http://ic.pics.livejournal.com/avezink/37757740/59266/59266_original.jpg',
                position: {
                    height: 200,
                    width: 200,
                },
            },
            text_one: {
                content: ['Guerreros de Terracota'],
            },
            text_two: {
                content: ['Miles de obreros y artistas chinos bajo la dirección de Qin Shi Huang'],
            },
        },
        {
            id: {
                code: 'ad89sdasd4499sdcvb',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1511'
                    }
                },
                lane: 1,
                y_offset: -382,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/04/school-of-athens.jpg?resize=625%2C417&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['La Escuela de Atenas'],
            },
            text_two: {
                content: ['Raphael'],
            },
        },
        {
            id: {
                code: 'ad89sdasd4499sdcvb',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1588'
                    }
                },
                lane: 1,
                y_offset: -95,
            },
            image: {
                url: 'https://upload.wikimedia.org/wikipedia/commons/3/37/El_entierro_del_se%C3%B1or_de_Orgaz_-_El_Greco.jpg',
                position: {
                    height: 200,
                    width: 120,
                },
            },
            text_one: {
                content: ['El entierro del conde de Orgaz'],
            },
            text_two: {
                content: ['El Greco'],
            },
        },
        {
            id: {
                code: 'ad8sdam0sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1428'
                    }
                },
                lane: 1,
                y_offset: -40,
            },
            image: {
                url: 'https://www.wga.hu/art/m/masaccio/brancacc/0view.jpg',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Frescos de la Capilla Brancacci'],
            },
            text_two: {
                content: ['Masaccio'],
            },
        },
        {
            id: {
                code: 'ad98b00sd04',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1498'
                    }
                },
                lane: 1,
                y_offset: -920,
            },
            image: {
                url: 'https://i.postimg.cc/sxjGbmSN/Davinci-last-supper-6.png',
                position: {
                    height: 200,
                    width: 277,
                },
            },
            text_one: {
                content: ['La Última Cena'],
            },
            text_two: {
                content: ['Leonardo da Vinci'],
            },
        },
        {
            id: {
                code: 'ad9x8b00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1908'
                    }
                },
                lane: 1,
                y_offset: -335,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/04/the-kiss-klimt.jpg?resize=625%2C627&ssl=1',
                position: {
                    height: 200,
                    width: 75,
                },
            },
            text_one: {
                content: ['Beso'],
            },
            text_two: {
                content: ['Gustav Klimt'],
            },
        },
        {
            id: {
                code: 'ad98b00osd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1434'
                    }
                },
                lane: 1,
                y_offset: -229,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/arnolfini-portrait1.jpg?resize=471%2C647&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Retrato de Arnolfini'],
            },
            text_two: {
                content: ['Jan van Eyck'],
            },
        },
        {
            id: {
                code: 'ad908b00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1499'
                    }
                },
                lane: 1,
                y_offset: -754,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2018/07/michelangelos_pieta.jpg?resize=625%2C658&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Pietà'],
            },
            text_two: {
                content: ['Miguel Ángel'],
            },
        },
        {
            id: {
                code: 'ada98b00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1931'
                    }
                },
                lane: 1,
                y_offset: -360,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/05/dali-persistence-of-memory.jpg?resize=625%2C460&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['La Persistencia de la Memoria'],
            },
            text_two: {
                content: ['Salvador Dali'],
            },
        },
        {
            id: {
                code: 'ad9s0a8b00sd4p',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '-100'
                    }
                },
                lane: 1,
                y_offset: 369,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/04/venus-de-milo-1.jpg?resize=425%2C638&ssl=1',
                position: {
                    height: 255,
                    width: 150,
                },
            },
            text_one: {
                content: ['Venus de Milo'],
            },
            text_two: {
                content: ['Alejandro de Antioquía'],
            },
        },
        {
            id: {
                code: 'ad98vb00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1665'
                    }
                },
                lane: 1,
                y_offset: -132,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/04/girl_with_the_pearl_earring_1665.jpg?resize=441%2C629&ssl=1',
                position: {
                    height: 200,
                    width: 123,
                },
            },
            text_one: {
                content: ['La Joven de la Perla'],
            },
            text_two: {
                content: ['Johannes Vermeer'],
            },
        },
        ////////////////////////////////////////////skipping
        {
            id: {
                code: 'ad988b00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '-40'
                    }
                },
                lane: 1,
                y_offset: 504,
            },
            image: {
                // url: 'https://womeninantiquity.wordpress.com/wp-content/uploads/2017/03/pompeii-mystery-frieze.jpg',
                url: 'https://tickingbombpompeii.weebly.com/uploads/4/9/1/5/49159749/4703858_orig.jpg',
                position: {
                    height: 200,
                    width: 220,
                },
            },
            text_one: {
                content: ['Villa de los Misterios'],
            },
            text_two: {
                content: ['Desconocido'],
            },
        },
        {
            id: {
                code: 'ad98b0xasx0sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '113'
                    }
                },
                lane: 1,
                y_offset: 154,
            },
            image: {
                url: 'https://s3-eu-west-1.amazonaws.com/vam-blog/dp-content/fb70c2170b970adb88a8218d1c6bd142.jpg',
                position: {
                    height: 200,
                    width: 140,
                },
            },
            text_one: {
                content: ['Columna de Trajano'],
            },
            text_two: {
                content: ['Apolodoro de Damasco'],
            },
        },
        {
            id: {
                code: 'ad98bhmjh00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '-9'
                    }
                },
                lane: 1,
                y_offset: -133.2,
            },
            image: {
                url: 'https://visitarroma.es/images/ara_pacis.jpg',
                position: {
                    height: 200,
                    width: 166,
                },
            },
            text_one: {
                content: ['Altar de la Paz Augusta'],
            },
            text_two: {
                content: ['Iniciativa del senado romano'],
            },
        },
        {
            id: {
                code: 'ad923e8b00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '-50'
                    }
                },
                lane: 1,
                y_offset: 186,
                x_offset: 40,
            },
            image: {
                url: 'https://zmkwesternciv.wordpress.com/wp-content/uploads/2016/06/greek_classic_polykleitos_doryphoros_c450bce.jpg',
                position: {
                    height: 310,
                    width: 105,
                },
            },
            text_one: {
                content: ['Doryphorus'],
            },
            text_two: {
                content: ['Polykleitos'],
            },
        },
        {
            id: {
                code: 'ad98b0b2n0sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '176'
                    }
                },
                lane: 1,
                y_offset: 140,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2017/04/marcus-aurelius.jpg?resize=506%2C637&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Estatua Ecuestre de Marco Aurelio'],
            },
            text_two: {
                content: ['Polykleitos'],
            },
        },
        {
            id: {
                code: 'ad98b0sg00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1444'
                    }
                },
                lane: 1,
                y_offset: 168,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2015/09/konrad_witz.jpg?resize=625%2C516&ssl=1',
                position: {
                    height: 200,
                    width: 125,
                },
            },
            text_one: {
                content: ['La Pesca Milagrosa'],
            },
            text_two: {
                content: ['Konrad Witz'],
            },
        },
        {
            id: {
                code: 'anc92cnal',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '-100'
                    }
                },
                lane: 1,
                y_offset: -18,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2017/04/battle-of-issues-mosaic.jpg?resize=625%2C292&ssl=1',
                position: {
                    height: 270,
                    width: 250,
                },
            },
            text_one: {
                content: ['Mosaico de la Batalla de Issos'],
            },
            text_two: {
                content: ['Copia romana de una pintura helenística (ca. 325 a. C.) retratando a Alejandro Magno en la Batalla de Issos (333 a.C.). Hallada en la Casa del Fauno, en Pompeya.'],
            },
            text_three: {
                content: ['c. I a. C.'],
            },
        },
        // {
        //     id: {
        //         code: 'xncaid0',
        //     },
        //     position: {
        //         type: 'date',
        //         date: {
        //             start: {
        //                 value: '-20'
        //             }
        //         },
        //         lane: 1,
        //         y_offset: 120,
        //     },
        //     image: {
        //         url: 'https://www.museivaticani.va/content/dam/museivaticani/immagini/collezioni/musei/braccio_nuovo/00_01_augusto_prima_porta.jpg/jcr:content/renditions/cq5dam.web.1280.1280.jpeg',
        //         position: {
        //             height: 200,
        //             width: 100,
        //         },
        //     },
        //     text_one: {
        //         content: ['Augusto de Prima Porta'],
        //     },
        //     text_two: {
        //         content: ['Desconocido'],
        //     },
        // },
        //////////////////////7 art history
        {
            id: {
                code: 'ad9e8asdab00sd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '260'
                    }
                },
                lane: 1,
                y_offset: 216,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/ludovisi_sarcophagus.jpg?resize=625%2C353&ssl=1',
                position: {
                    height: 200,
                    width: 147,
                },
            },
            text_one: {
                content: ['Sarcófago Ludovisi'],
            },
            text_two: {
                content: ['Desconocido'],
            },
        },
        {
            id: {
                code: 'adcd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '305'
                    }
                },
                lane: 1,
                y_offset: 345,
            },
            image: {
                url: 'https://i.ibb.co/5gCsdh8w/four-tetrarchs4.jpg',
                position: {
                    height: 200,
                    width: 76,
                },
            },
            text_one: {
                content: ['Los Cuatro Tetrarcas'],
            },
            text_two: {
                content: ['Desconocido'],
            },
        },
        {
            id: {
                code: 'x9cnq4rq',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '315'
                    }
                },
                lane: 1,
                y_offset: -52,
            },
            image: {
                url: 'https://cdn.pixabay.com/photo/2016/06/22/14/13/rome-1473043_1280.jpg',
                position: {
                    height: 140,
                    width: 140,
                },
            },
            text_one: {
                content: ['Arco de Constantino'],
            },
            text_two: {
                content: ['Desconocido'],
            },
        },
        {
            id: {
                code: 'ascsd4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '485'
                    }
                },
                lane: 1,
                y_offset: -200,
            },
            image: {
                url: 'http://www.mundodelmuseo.com/fichas/preachingbuda.jpg',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Buda Predicando'],
            },
            text_two: {
                content: ['Desconocido'],
            },
        },
        {
            id: {
                code: 'PKgwIqhrN',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '550'
                    }
                },
                lane: 1,
                y_offset: 15,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2021/02/vienna-genesis-2.jpg?resize=625%2C311&ssl=1',
                position: {
                    height: 140,
                    width: 200,
                },
            },
            text_one: {
                content: ['Genesis de Vienna'],
            },
            text_two: {
                content: ['Manuscrito cristiano ilustrado más antiguo que se conoce en existencia'],
            },
        },
        {
            id: {
                code: 'gQAVnW',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '548'
                    }
                },
                lane: 1,
                y_offset: -277,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2021/02/san-vitale-apse.jpg?resize=625%2C469&ssl=1',
                position: {
                    height: 200,
                    width: 125,
                },
            },
            text_one: {
                content: ['Mosáicos en la Basilica de San Vitale'],
            },
            text_two: {
                content: ['Desconocido'],
            },
        },
        {
            id: {
                code: 'LLwpJQjun',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '700'
                    }
                },
                lane: 1,
                y_offset: 88,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2022/10/Sadashiva-Elephanta.jpg?resize=1024%2C715&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Shiva Eterna'],
            },
            text_two: {
                content: ['Desconocido'],
            },
        },
        {
            id: {
                code: 'rpEVdpBHmAQpOCiibL',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '566'
                    }
                },
                lane: 1,
                y_offset: -128,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2021/02/transfiguration-st-catherine.png?resize=625%2C424&ssl=1',
                position: {
                    height: 200,
                    width: 136,
                },
            },
            text_one: {
                content: ['Transfiguración de Cristo'],
            },
            text_two: {
                content: ['Representación artística más temprana que se conoce'],
            },
        },
        {
            id: {
                code: 'OGNZOgOFQExhLbnacsEP',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '599'
                    }
                },
                lane: 1,
                y_offset: -50.8,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2021/02/rossano-gospels.jpg?resize=527%2C631&ssl=1',
                position: {
                    height: 200,
                    width: 138,
                },
            },
            text_one: {
                content: ['Pergamino Purpúreo'],
            },
            text_two: {
                content: ['Primer manuscrito ilustrado que se conoce del Nuevo Testamento'],
            },
        },
        // {
        //     id: {
        //         code: 'zkryZqUJLsQtLhL',
        //     },
        //     position: {
        //         type: 'date',
        //         date: {
        //             start: {
        //                 value: '565'
        //             }
        //         },
        //         lane: 1,
        //         y_offset: -40,
        //     },
        //     image: {
        //         url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Katharinenkloster_Sinai_BW_2.jpg/1076px-Katharinenkloster_Sinai_BW_2.jpg',
        //         position: {
        //             height: 200,
        //             width: 100,
        //         },
        //     },
        //     text_one: {
        //         content: ['Monasterio de Santa Catalina'],
        //     },
        //     text_two: {
        //         content: ['Monasterio habitado más antiguo'],
        //     },
        // },
        {
            id: {
                code: 'ascmmwosdl4',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '692'
                    }
                },
                lane: 1,
                y_offset: -58
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/08/dome-of-the-rock.jpg?resize=625%2C416&ssl=1',
                position: {
                    height: 200,
                    width: 157,
                },
            },
            text_one: {
                content: ['Domo de la Roca'],
            },
            text_two: {
                content: ['El más antiguo de los edificios musulmanes conservados'],
            },
        },
        {
            id: {
                code: 'LShVFjuSohjmbyqurat',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '715'
                    }
                },
                lane: 1,
                y_offset: 200,
            },
            image: {
                url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/LindisfarneFol27rIncipitMatt.jpg/507px-LindisfarneFol27rIncipitMatt.jpg',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Evangelios de Lindisfarne'],
            },
            text_two: {
                content: ['Eadfrith'],
            },
        },
        {
            id: {
                code: 'lzmRZcLNQzAE',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '800'
                    }
                },
                lane: 1,
                y_offset: 241,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/book-of-kells.jpg?resize=477%2C593&ssl=1',
                position: {
                    height: 200,
                    width: 80,
                },
            },
            text_one: {
                content: ['Libro de Kells'],
            },
            text_two: {
                content: ['Escocia. El más famoso de los manuscritos ilustrados medievales por la complejidad, el detalle y la majestuosidad de las ilustraciones. <a href="https://www.worldhistory.org/trans/es/1-16744/el-libro-de-kells/">↗</a>'],
            },
        },
        {
            id: {
                code: 'znjgkQU',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '835'
                    }
                },
                lane: 1,
                y_offset: 241,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/05/ebbo-1.jpg?resize=291%2C403&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Los Evangelios de Ebbo'],
            },
            text_two: {
                content: ['Manuscritos ilustrados con estilo <a href="https://historyofinformation.com/detail.php?id=1700" target="_blank">innovador e influyente</a>.'],
            },
        },
        {
            id: {
                code: 'EgJMeWjnvluoIC',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '976'
                    }
                },
                lane: 1,
                y_offset: 222,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2014/05/gero-crucifix.jpg?resize=424%2C660&ssl=1',
                position: {
                    height: 230,
                    width: 100,
                },
            },
            text_one: {
                content: ['Cruz de Gero'],
            },
            text_two: {
                content: ['976. Alemania. Escultura de gran tamaño más antigua de Cristo crucificado. Primera representación occidental conocida de Cristo en la cruz estando muerto; las representaciones anteriores tenían a Cristo apareciendo vivo.'],
            },
        },
        {
            id: {
                code: 'lbSkdeyTGFhicDrx',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1160'
                    }
                },
                lane: 1,
                y_offset: 284,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2021/02/domingo-de-silos-descent.jpg?resize=625%2C625&ssl=1',
                position: {
                    height: 200,
                    width: 150,
                },
            },
            text_one: {
                content: ['Esculturas en relieve, Monasterio Sto. Domingo de Silos'],
            },
            text_two: {
                content: [''],
            },
        },
        {
            id: {
                code: 'hLtUvGJqJuDqxGUgMyJ',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1160'
                    }
                },
                lane: 1,
                y_offset: 480,
            },
            image: {
                url: 'http://www.mundodelmuseo.com/fichas/pentecostes.jpg',
                position: {
                    height: 200,
                    width: 182,
                },
            },
            text_one: {
                content: ['Tímpano: Pentecostés y Gran Comisión'],
            },
            text_two: {
                content: [''],
            },
        },
        {
            id: {
                code: 'SrMWJCEmnpJYJuSHeTS',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1220'
                    }
                },
                lane: 1,
                y_offset: 194,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2013/06/chartres-cathedral-royal-portal.jpg?resize=625%2C610&ssl=1',
                position: {
                    height: 200,
                    width: 126,
                },
            },
            text_one: {
                content: ['Esculturas en relieve de la Catedral de Chartres'],
            },
            text_two: {
                content: [''],
            },
        },
        {
            id: {
                code: 'iRcWOzvBocFZXpBcl',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1235'
                    }
                },
                lane: 1,
                y_offset: 400,
            },
            image: {
                url: 'https://i0.wp.com/beckchris.com/wp-content/uploads/2021/02/chartres-south-rose-window.jpg?resize=427%2C647&ssl=1',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Vitrales en la Catedral de Chartres'],
            },
            text_two: {
                content: ['La mayor colección de vitrales (167) en existencia'],
            },
        },
        {
            id: {
                code: 'PhStsuDnCuRI',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '1275'
                    }
                },
                lane: 1,
                y_offset: 200,
            },
            image: {
                url: 'https://upload.wikimedia.org/wikipedia/commons/e/e1/Reims_Kathedrale.jpg',
                position: {
                    height: 200,
                    width: 100,
                },
            },
            text_one: {
                content: ['Esculturas en relieve de la Catedral de Reims'],
            },
            text_two: {
                content: [''],
            },
        },
        {
            id: {
                code: 'PhStsuDnCuRI',
            },
            position: {
                type: 'date',
                date: {
                    start: {
                        value: '-1406'
                    }
                },
                lane: 1,
                y_offset: 346,
            },
            image: {
                url: 'https://d33y0z4ooepzrm.cloudfront.net/images/94c27158cb0d0f0948a754c1e52b0376424b8e7b/fullscreen/94c27158cb0d0f0948a754c1e52b0376424b8e7b.jpg',
                position: {
                    height: 200,
                    width: 150,
                },
            },
            text_one: {
                content: ['Israel cruzando el Jordán'],
            },
            text_two: {
                content: ['Frans Francken II, c. 1650'],
            },
        },
        //////////////////////////////////////// this is a random image:
    ],
}

export function getTopArt() {
    return {
        ...top_art_beckchris_normal,
        elements: top_art_beckchris_normal.elements?.map((e, i) => {
            const text_one = e.text_one?.content ? e.text_one?.content[0] : ''
            const text_two = e.text_two?.content ? e.text_two?.content[0] : ''
            const text_three = e.text_three?.content ? e.text_three?.content[0] : ''
            return {
                ...e,
                image: {},
                text_one: {},
                text_two: {},
                text_three: {},
                html: [{
                    foreign_object_position: {
                        width: e.image?.position?.width,
                        height: e.image?.position?.height,
                    },
                    content: `
                        ${
                            !text_three ? '' : `
                                <p style='
                                        font-size: 6px;
                                        text-align: center;
                                        margin-top: 0px;
                                        margin-bottom: 4px;
                                        color: gray;
                                        font-weight: 400;
                                '>${text_three}</p>
                            `
                        }
                        <img src='${e.image?.url}'
                            style='
                                border-radius: 4px;
                                min-width: 100%;
                                max-width: 100%;
                            '
                        >
                        <p style='
                            text-align: center;
                            font-family: "Gilda Display";
                            font-size: 10px;
                            margin-top: 2px;
                            margin-bottom: 0px;
                            letter-spacing: -0.03em;
                        '>
                            ${text_one}
                        </p>
                        <p style='
                            font-size: 6px;
                            text-align: center;
                            margin-top: 0px;
                            margin-bottom: 0px;
                            color: gray;
                            font-weight: 400;
                        '>
                            ${text_two}
                        </p>
                    `
                }
            ],
            }
        }),
    }
}

function elementsToString(elements: any): string {
    return JSON.stringify(elements, null, 2); // Pretty-print the JSON
}

// this is ready to be used i think
export function get_parsed_art() {
    // const sortedElements = sortTimelineElements(personas_destacadas.elements || []);
    // const sortedElementsString = elementsToString(sortedElements);

    // Sort the elements
    // const sortedElements = s(personas_destacadas.elements || []);

    // Assign lanes to elements without a y_offset
    // const elementsWithLanes = assignLanes(sortedElements);

    // Convert the elements to a string
    // const sortedElementsString = elementsToString(elementsWithLanes);
    
    // Copy to clipboard (this works in modern browsers)
    navigator.clipboard.writeText(elementsToString(getTopArt())).then(() => {
        console.log('Sorted elements copied to clipboard!');
    }).catch(err => {
        console.error('Failed to copy:', err);
    });
}