import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import './raiz_de_amargura.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'
import High from '../High'
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import Aside from '../Aside'
import { iBlogTestProps } from '../styles/BlogStyleA'
import Outline from '../Outline'

const badge = 'Introducción'
const title = 'Amis\u00ADtad con el mun\u00ADdo'
// const html_title = <span>Septua<span className='shy'></span>ginta</span>
// const subtitle = 'Todo aquel que quiera ser amigo del mundo, se declara enemigo de Dios — Santiago 4:4, RVC.'
const subtitle = ''
// const subtitle = 'Indicadores y preguntas clave'

export default function AmistadConElMundo() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            amor: {
                content: <>.</>,
                // type: 'comment',
            },
        }

        return (
            <Box
                className='ArticleBody'
                sx={{
                    '& .HighlighterBlog': {
                        textDecorationColor: '#F4FE03'
                    }
                }}
            >
                <Container>
                </Container>
                <Box
                    sx={{
                        '& .MuiTypography-root': {
                            textAlign: 'center',
                            // fontSize: '24px',
                            fontFamily: 'Satoshi !important',
                            // maxWidth: '700px'
                            pb: '0px',
                            wordSpacing: 'initial !important',
                        },
                        mb: '1.8em',
                    }}
                    >
                    <Box
                        sx={{
                            background: '#A73212',
                            pt: '6px',
                            pb: '8px',
                        }}
                    >    
                        <Typography
                            sx={{
                                color: '#F0EBE3',
                            }}
                        >
                            Todo aquel que quiera ser amigo del mundo, se declara enemigo de Dios.
                        </Typography>
                        <Typography
                            sx={{
                                color: '#F0EBE3',
                                // fontSize: '0.8em',
                            }}
                        >
                            <span style={{fontSize: '0.8em'}}>
                                — Santiago 4:4 (RVC)
                            </span>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            background: '#ECDDFE',
                            pt: '6px',
                            pb: '8px',
                        }}
                        >   
                        <Typography
                            sx={{
                                color: '#FF5500',
                            }}
                        >
                            Si alguno ama al mundo, el amor del Padre no está en él.
                        </Typography>
                        <Typography
                            sx={{
                                color: '#FF5500',
                            }}
                        >
                            <span style={{fontSize: '0.8em'}}>
                                — 1 Juan 2:15
                            </span>
                        </Typography>
                    </Box>
                </Box>
                <Container>
                    <Typography>
                    <span style={{
                        fontSize: '406%',
                        fontVariationSettings: '"wght" 900',
                        float: 'left',
                        lineHeight: 1,
                        background: '#6E7D7C',
                        color: '#E8D5C1',
                        // background: '#B3AF92',
                        // color: '#F9EDDF',
                        // background: '#A3AC7D',
                        // color: '#EFEDE0',
                        // background: '#525B2C',
                        // color: '#F5DAC5',
                        marginTop: '0.1em',
                        marginBottom: '-0.1em !important',
                        marginRight: '0.1em',
                        // padding depends on the exact letter
                        paddingRight: '0.07em',
                        // paddingLeft: '0.1em',
                        paddingLeft: '0.07em',
                    }}>M</span>uchas veces la invitación del mundo tiene las palabras correctas: justicia, igualdad, tolerancia, libertad, honestidad, prosperidad, amor, placer, amistad, arte, entretenimiento. Pero aunque suenan bien, ninguna de estas palabras significa lo mismo lejos de Dios. Por ejemplo, la justicia humana puede ser muy injusta (<BibleRef>Is 10:1-2, 64:6; Am 5:12; Mic 7:3; Sof 3:3; 1 Re 21:5-15; Lucas 16:15</BibleRef>), mientras que la justicia de Dios restaura y corrige (<BibleRef>Dt 32:4; Sal 103:6; Pr 16:11; Is 45:21; Zac 3:5; Jn 5:30; Ro 2:2; Ap 15:3</BibleRef>). Por esta razón las grandes propuestas del mundo siempre decepcionan.
                    </Typography>
                    <Typography variant='h2'>
                        Autoanálisis
                    </Typography>
                    <Typography>
                        Estos pueden ser algunos indicadores de que amamos al mundo más de lo que nos hemos dado cuenta:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                {/* Jesús prometio que volvería y que este seria el fin del mundo como lo conocemos. */}
                                <span className='InlineHeader'>Desinterés por la venida de Cristo.</span> Jesús prometió que iba a volver para gobernar con justicia y para guiarnos a una vida como Él desea (<BibleRef>Jn 14:2-3, Mt 25:30-46</BibleRef>). A veces una persona no quiere que Cristo vuelva porque está cómoda en este mundo, es amiga de él y prefiere que todo siga igual. Pero no podemos estar de acuerdo con esta postura. Recordemos que en el mundo hay incontables injusticias, mentiras, y dolor. Sería mejor que Jesús vuelva y gobierne con perfección, amor, y bondad.
                            </Typography>
                            <Aside pallette='green_one'>
                                <Typography>
                                    En otras ocasiones la falta de interés por el regreso de Cristo no tiene nada que ver con el amor al mundo. 
                                </Typography>
                                <Typography>
                                    Por ejemplo, a veces alguien desconfía de Dios y tiene miedo de cómo será la vida cuando Jesús vuelva. Esto no es amor al mundo. Pero recordemos que Dios es bueno: murió en una cruz por nosotros y perdonó todos nuestros errores. No hay razón para tenerle miedo.
                                </Typography>
                                <Typography>
                                    Otro ejemplo podría ser cuando alguien se preocupa porque algún ser querido todavía no conoce a Dios. Esto no es amor al mundo. Es entendible, y Dios comparte su preocupación <BibleRef parentheses>2 Pe 3:8-10</BibleRef>. Sin embargo, nuestra oración debe ser para que nuestros seres queridos conozcan a Dios y Cristo vuelva.
                                </Typography>
                            </Aside>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Ausencia de metas espirituales.</span> Si todos nuestros deseos y metas están orientados hacia cosas seculares, es posible que nuestro amor por el mundo esté guiando nuestras decisiones. No está mal trabajar por cosas de este mundo (estudio, trabajo, entretenimiento, etc.) pero tiene que haber equilibrio.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Necesidad de quedar bien con otros.</span> La opinión de los demás no puede ser más importante que la de Dios:
                            </Typography>
                            <Typography>
                                <Quote>
                                    ¿Busco ganarme la aprobación humana o la de Dios? Si mi objetivo fuera agradar a los demás, no sería un siervo de Cristo — <BibleRef>Gálatas 1:10</BibleRef> (paráfrasis)
                                </Quote>
                            </Typography>
                            <Aside pallette='green_one'>
                                <Typography>
                                    Esto no significa que siempre debamos ignorar la opinión de los demás.
                                </Typography>
                                <Typography>
                                    Según el contexto literario anterior de Gálatas el Apóstol no se refiere a la aprobación de los demás en general, sino a aquellas situaciones donde la opinion de las personas y la de Dios son opuestas y, en estos casos, debe ser más importante la opinión de Dios (similar a <BibleRef>Hch 5:29</BibleRef>).
                                </Typography>
                                <Typography>
                                    En el resto de los casos hay que aplicar el sentido común y actuar de la mejor manera según requiera la situación. Hay algunas enseñanzas bíblicas sobre esto: Hay que saber aceptar los buenos consejos (<BibleRef>Pr 12:15</BibleRef>). Hay que intentar mantener la paz con todos (<BibleRef>He 12:14</BibleRef>). Hay que evitar ser piedra de tropiezo aún si lo que hago está bien (<BibleRef>Rom 14:13–23</BibleRef>). Hay que tener en cuenta la opinión de los demás para evangelizar (<BibleRef>Mt 5:16, 1 Co 9:20-22</BibleRef>). También observamos que Jesús era bien visto por las personas (<BibleRef>Lc 2:52</BibleRef>), extendía su amistad a todos (<BibleRef>Mr 2:16</BibleRef>), y a veces hacía cosas solo para llevarse bien con otros (<BibleRef>Mt 17:27</BibleRef>).
                                </Typography>
                            </Aside>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Descuido de vida espiritual.</span> Si durante días enteros nos olvidamos de Dios le estamos dando al mundo un lugar que no debería ocupar. Donde está lo que valoramos está nuestro corazón: <BibleRef reference='Ro 8:5; Mt 16:23; Fil 3:19; 1 Cr 22:19; Sal 91:14: Pr 23:5; Sal 49:11-17'><b>Ro 8:5</b>; Col 3:1-3; Mt 16:23; Fil 3:19; 1 Cr 22:19; Sal 91:14: Pr 23:5; Sal 49:11-17</BibleRef>.
                                {/* Acá tambien hay excepciones. Por ejemplo, no entender la importancia de la vida espiritual */}
                                {/* Además, los deseos y preocupaciones que provoca el mundo pueden ahogar la voz de Dios en nuestra vida <BibleRef parentheses>Mateo 13:22</BibleRef>. */}
                            </Typography>
                        </Typography>
                    </Typography>
                    {/* <Typography component='li'>
                        <Typography>
                            <span className='InlineHeader'>Preferencia por actividades del mundo.</span> Para el cristiano promedio, las actividades espirituales ocupan solo un 5% de su tiempo. Cuando alguien no quiere darle a Dios ni siquiera ese tiempo y prefiere gastarlo en actividades seculares, es posible que sea más amigo del mundo que de Dios.
                        </Typography>
                    </Typography> */}
                    <Typography variant='h2'>
                        ¿Por qué no puedo ser amigo de ambos?
                    </Typography>
                    <Typography>
                        La respuesta es que hay al menos tres razones: (1) porque lo que ofrece el mundo es incompatible con Dios, (2) porque hay solamente una solución, y (3) porque es inevitable decidir.
                    </Typography>
                    <Typography variant='h3'>
                        Son incompatibles
                    </Typography>
                    <Typography>
                        {/* Porque Dios y el mundo son incompatibles, y tarde o temprano debemos elegir entre uno y otro */}
                        {/* Lo que ofrece el mundo es incompatible con Dios. */}
                        La primera razón por la que necesitamos elegir entre ambos es que lo que ofrece el mundo es incompatible con Dios. En el mundo hay deseos, herramientas, y resultados que dañan a las personas.
                    </Typography>
                    <Typography variant='h4'>
                        Lo malo que ofrece el mundo
                    </Typography>
                    <Typography>
                        Algunas cosas que ofrece el mundo son claramente negativas, por ejemplo, Juan explica:
                    </Typography>
                    <Typography>
                    {/* Omitido: Porque */}
                        <Quote>
                            [...] todo lo que hay en el mundo, los deseos de la carne, los deseos de los ojos, y la vanagloria de la vida, no proviene del Padre, sino del mundo. — <BibleRef>1 Juan 2:16</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        Y estos son algunos de los deseos/frutos de la carne, mencionados en el versículo anterior:
                    </Typography>
                    <Typography>
                        <Quote>
                            [...] adulterio, fornicación, inmundicia, lascivia, idolatría, hechicerías, enemistades, pleitos, celos, iras, contiendas, disensiones, herejías, envidias, homicidios, borracheras, orgías, y cosas semejantes a estas [...] — <BibleRef>Gálatas 5:19-21</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography variant='h4'>
                        Lo bueno que ofrece el mundo
                    </Typography>
                    <Typography>
                        Otras cosas que ofrece el mundo aparentan ser buenas, aunque al final resultan decepcionantes. Un ejemplo es la justicia. Estos son algunos textos bíblicos que mencionan la justicia humana:
                    </Typography>
                    <Typography>
                        <Quote>
                            ¡Yo sé que sus crímenes son incontables, que sus injusticias no tienen límite! Ustedes oprimen a la gente honrada, los jueces se venden por dinero y en los tribunales condenan injustamente a los pobres. — <BibleRef>Amós 5:12, NBV</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            ¡Con ambas manos son hábiles para hacer el mal! Tanto los funcionarios como los jueces exigen sobornos. La gente con influencia obtiene lo que quiere y juntos traman para torcer la justicia. — <BibleRef>Miqueas 7:3, NTV</BibleRef>
                        </Quote>
                    </Typography>
                    <Aside pallette='yellow_one' icon='add comment'>
                        {/* <Typography>
                            "¡Con ambas manos son hábiles para hacer el mal!" — Esto es lenguaje figurado. Cuando un jugador de fútbol es muy talentoso a veces no tiene "pierna débil" y puede jugar con los dos pies por igual. Lo mismo pasa en el el tenis, música, escritura, etc., pero con las manos. Esta expresión señala que estas personas eran así de habilidosas pero para hacer el mal.
                        </Typography> */}
                        <Typography>
                            {/* "¡Con ambas manos son hábiles para hacer el mal!" — Esto es lenguaje figurado. Cuando un jugador de fútbol es muy talentoso a veces no tiene "pierna débil" y puede jugar con los dos pies por igual. Cuando un tenista es muy talentoso a veces no tiene "brazo débil" y puede jugar con las dos manos por igual. Esta gente también era habilidosa al punto de "no tener mano débil", pero en vez de ser deportistas eran expertos en hacer el mal. */}
                            "¡Con ambas manos son hábiles para hacer el mal!" — Esto es lenguaje figurado. Cuando un jugador de fútbol es muy talentoso a veces no tiene "pierna débil" y puede jugar con los dos pies por igual. Cuando un tenista es muy talentoso a veces no tiene "brazo débil" y puede jugar con las dos manos por igual. Esta gente también era tan habilidosa que parecían "no tener mano débil" pero, en vez de ser deportistas, eran expertos en hacer el mal.
                        </Typography>
                    </Aside>
                    <Typography>
                        <Quote>
                            Sus líderes son como leones rugientes en cacería de sus víctimas. Sus jueces son como lobos voraces al anochecer, que para la mañana no han dejado rastro de su presa. — <BibleRef>Sofonías 3:3, NTV</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            ¡Qué mal les va a ir a ustedes los que inventan leyes insoportables e injustas! ¡Ustedes no protegen a los débiles ni respetan los derechos de los pobres; maltratan a las viudas y les roban a los huérfanos. — <BibleRef>Isaías 10:1-2, TLA</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Estamos completamente contaminados e inmundos de pecado. Nuestros actos de justicia, no son más que trapos sucios. Como hojas de otoño nos decoloramos, nos marchitamos y caemos. Como viento, nos arrastran nuestros pecados. — <BibleRef>Isaías 64:6</BibleRef>, NBV + NTV
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Entonces él les dijo: «A ustedes les encanta aparecer como personas rectas en público, pero Dios conoce el corazón. Lo que este mundo honra es detestable a los ojos de Dios. — <BibleRef>Lucas 16:15, NTV</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        Gran diferencia entre esto y lo que Dios propone como justicia:
                    </Typography>
                    <Typography>
                        <Quote>
                            Él es la Roca; sus obras son perfectas. Todo lo que hace es justo e imparcial. Él es Dios fiel; nunca actúa mal. ¡Qué justo y recto es él. — <BibleRef>Deuteronomio 32:4, NTV</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Él hace justicia a cuantos son tratados injustamente. — <BibleRef>Salmos 103:6, NBV</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Las pesas y las balanzas justas son del Señor; él es quien fija los parámetros de la justicia — <BibleRef>Proverbios 16:11</BibleRef>, NVI + NTV
                        </Quote>
                    </Typography>
                    <Aside pallette='yellow_one' icon='add comment'>
                        <Typography>
                            "Él es quien fija los parámetros de la justicia" — El texto original usa lenguaje figurado y es difícil determinar su significado con certeza. La NTV adopta este sentido que destaca una diferencia clave entre la justicia humana y la divina: "¿Quién decide lo que está bien o mal?"
                        </Typography>
                        <Typography>
                            La justicia del mundo no encuentra una respuesta definitiva. En la actualidad una postura popular es que "cada uno tiene su propia verdad". Este "relativismo moral" es atractivo porque "valida y afirma" las opiniones de todos. Pero es imposible de poner en práctica. ¿Quién decide si algo es un crimen o está bien? ¿Quién decide lo que es saludable o no? Si cada uno puede usar su propia definición cualquier cosa es justificable — hasta las acciones mas horribles. El mundo propone distintas respuestas a esta pregunta, pero todas tienen el mismo problema.
                        </Typography>
                        <Typography>
                            La verdadera justicia se refiere a Dios para establecer los parámetros de lo bueno y lo malo. Las reglas son justas e iguales para todos. Claramente también hay desafíos para poner esto en práctica. Por ejemplo, sabemos que conocer la voluntad de Dios no siempre es fácil, y también sabemos que se pueden justificar muchos abusos usando a Dios como excusa. Dicho esto, incluso en casos de incertidumbre hay principios bíblicos y teológicos a los que se puede recurrir para informar cada decision.
                        </Typography>
                    </Aside>
                    <Typography>
                        {/* Otro ejemplo de cómo algo puede parecer bueno pero resultar decepcionante es la libertad. Según explica Jesús, en el mundo no existe la verdadera libertad, ya que las personas son esclavas del pecado: */}
                        Otro ejemplo de algo del mundo que parece bueno pero resulta decepcionante es la libertad. Según la Biblia, el mundo vende una libertad que no es capaz de entregar:
                    </Typography>
                    <Typography>
                        <Quote>
                            Jesús les respondió: En verdad, en verdad os digo que todo el que comete pecado es esclavo del pecado; — <BibleRef>Juan 8:34, LBLA</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        Lo que el mundo ofrece como "libertad" no es otra cosa que esclavitud al pecado, y de nuevo encontramos que Dios ofrece algo mucho mejor:
                    </Typography>
                    <Typography>
                        <Quote>
                            porque también la creación misma será libertada de la esclavitud de corrupción, a la libertad gloriosa de los hijos de Dios. — <BibleRef>Romanos 8:21</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Por eso, si el Hijo les da la libertad, serán verdaderamente libres. — <BibleRef>Juan 8:36, BLPH</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            como libres, pero no como los que tienen la libertad como pretexto para hacer lo malo, sino como siervos de Dios. — <BibleRef>1 Pedro 2:16</BibleRef>
                        </Quote>
                    </Typography>
                    <Aside pallette='yellow_one' icon='add comment'>
                        <Typography>
                            También hay otros problemas con la noción de "libertad" del mundo. Por ejemplo, si somos simplemente un conjunto de reacciones químicas y biológicas no existe verdaderamente el libre albedrío, sino que nuestras acciones están determinadas tanto por el contexto como por nuestros mecanismos internos de respuesta a estos.
                        </Typography>
                    </Aside>
                    <Typography variant='h3'>
                        Hay solamente una solución
                    </Typography>
                    <Typography>
                        <Quote>
                            El que no está conmigo, está contra mí; y el que no recoge conmigo, desparrama. — <BibleRef>Mateo 12:30, LBLA</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        Sabemos que solamente Cristo sana, salva y santifica: por más maravillosa que sea cualquier otra cosa, no es lo que verdaderamente necesitan los demás.
                    </Typography>
                    <Typography>
                        Supongamos que un nene se enferma y sus padres encuentran un tratamiento que garantiza su recuperación total. Sin embargo, antes de pagar el tratamiento el padre se gasta todo el dinero en el casino. ¿Está bien esta decisión? Claramente no: la primera responsabilidad del padre es con su hijo.
                    </Typography>
                    <Typography>
                        Ahora supongamos que, en lugar de gastar en un casino, el padre dona todo el dinero a una fundación protectora de animales. ¿Está bien esta decisión? Claramente no: apoyar una fundación de animales es una causa noble, pero no soluciona el problema que enfrentan como familia.
                    </Typography>
                    <Typography>
                        En nuestra vida sucede algo similar. La humanidad tiene un problema con el pecado, y solamente Jesús puede resolverlo. Hay muchas otras formas de ayudar, pero si no acercamos el mensaje de Cristo estamos causando daño.
                    </Typography>
                    <Typography>
                        Hacemos el bien acercando el reino de Dios a la tierra, o hacemos el mal abriendo camino al reino de Satanás. Acercamos a la gente a la vida eterna con Dios o la acercamos a la condenación perpetua con Satanás.
                    </Typography>
                    <Typography variant='h3'>
                        Es inevitable decidir
                    </Typography>
                    <Typography>
                        Lo antedicho también es cierto desde una perspectiva individual. Ser neutrales no es una opción: Si no nos hidratamos nos deshidratamos. Si no nos nutrimos nos desnutrimos. Si no nos fortalecemos espiritualmente nos debilitamos. Si no hacemos ejercicio espiritual ejercitamos la carne. Si no escuchamos la voz de Dios, escuchamos la de Satanás. El tiempo pasa y debemos tomar decisiones. Si no avanzamos, retrocedemos.
                    </Typography>
                    <Typography variant='h2'>
                        Ministerio de la reconciliación
                    </Typography>
                    <Typography>
                        <Quote>
                            Y todo esto proviene de Dios, quien nos reconcilió consigo mismo por Cristo, y nos dio el ministerio de la reconciliación. — <BibleRef>2 Corintios 5:18</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography variant='h2'>
                        ¿Cómo llegamos hasta acá?
                    </Typography>
                    <Typography>
                        Entender cómo llegamos hasta acá puede ayudarnos a enmarcar las ideas previas y afirmar nuestra interpretación de la realidad.
                    </Typography>
                    <Typography variant='h3'>
                        Autoridad del ser humano
                    </Typography>
                    <Typography>
                        Esta historia comienza cuando Dios le da autoridad al ser humano para gobernar la tierra:
                    </Typography>
                    <Typography>
                        <Quote>
                            Entonces dijo Dios: Hagamos al hombre a nuestra imagen, conforme a nuestra semejanza; y señoree en los peces del mar, en las aves de los cielos, en las bestias, en toda la tierra, y en todo animal que se arrastra sobre la tierra. — <BibleRef>Génesis 1:26</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Luego Dios los bendijo con las siguientes palabras: «Sean fructíferos y multiplíquense. Llenen la tierra y gobiernen sobre ella. Reinen sobre los peces del mar, las aves del cielo y todos los animales que corren por el suelo». — <BibleRef>Génesis 1:28, NTV</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        A través de la Biblia continuamos viendo este patrón:
                    </Typography>
                    <Typography>
                        <Quote>
                            Los pusiste a cargo de todo lo que creaste, y sometiste todas las cosas bajo su autoridad: — <BibleRef>Salmos 8:6, NTV</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Los cielos son de Dios, y a nosotros nos confió la tierra. — <BibleRef>Salmos 115:16, TLA</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Por mi gran poder yo hice la tierra; toda la humanidad y todos los animales, y esto, que es mío, lo doy a quien quiero. — <BibleRef>Jeremías 27:5, NBV</BibleRef>
                        </Quote>
                    </Typography>
                    <Aside pallette='gray_one' icon='add comment'>
                        <Typography>
                            El versículo anterior está en un contexto literario que no es idéntico a este, pero la idea se traslada y aplica perfectamente.
                        </Typography>
                    </Aside>
                    <Typography>
                        <Quote>
                            Y Jesús se acercó y les habló diciendo: Toda potestad me es dada en el cielo y en la tierra. Por tanto, id, y haced discípulos a todas las naciones, bautizándolos en el nombre del Padre, y del Hijo, y del Espíritu Santo; enseñándoles que guarden todas las cosas que os he mandado; y he aquí yo estoy con vosotros todos los días, hasta el fin del mundo. Amén. — <BibleRef>Mateo 28:18-19</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            Volvieron los setenta con gozo, diciendo: Señor, aun los demonios se nos sujetan en tu nombre. Y les dijo: Yo veía a Satanás caer del cielo como un rayo. He aquí os doy potestad de hollar serpientes y escorpiones, y sobre toda fuerza del enemigo, y nada os dañará. — <BibleRef>Lucas 10:17-19</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        En los dos últimos textos vemos cómo Jesús recibe autoridad pero, en lugar de ejercerla de forma directa, se la entrega a sus discípulos para que estos cumplan con sus planes y deseos. Es notable que Jesús podría haberle encomendado la tarea de predicar a los ángeles, o hacerlo el mismo, pero decidió delegarlo en las personas.
                    </Typography>
                    <Typography>
                        La Biblia también narra los planes de Dios para el futuro, donde se ven realizados los deseos del Señor de que sus hijos reinen:
                    </Typography>
                    <Typography>
                        <Quote>
                            y que el reino, y el dominio y la majestad de los reinos debajo de todo el cielo, sea dado al pueblo de los santos del Altísimo, cuyo reino es reino eterno, y todos los dominios le servirán y obedecerán. — <BibleRef>Daniel 7:27</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            ¿O no sabéis que los santos han de juzgar al mundo? Y si el mundo ha de ser juzgado por vosotros, ¿sois indignos de juzgar cosas muy pequeñas? — <BibleRef>1 Corintios 6:2</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            y nos has hecho para nuestro Dios reyes y sacerdotes, y reinaremos sobre la tierra — <BibleRef>Apocalipsis 5:10</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        De esta forma la Biblia muestra que Dios les dió autoridad a los humanos para que gobiernen la tierra. Además, vemos que reinar la tierra es una tarea, función, y misión encomendada al ser humano.
                    </Typography>
                    <Typography variant='h3'>
                        Reglas claras
                    </Typography>
                    <Typography>
                        Después de que el ser humano recibe autoridad Dios establece las pautas del primer pacto con nosotros: las personas podían hacer todo excepto comer del Árbol del Conocimiento del Bien y del Mal, y la consecuencia de hacerlo sería la muerte.
                    </Typography>
                    <Typography>
                        <Quote>
                            Y mandó Jehová Dios al hombre, diciendo: De todo árbol del huerto podrás comer; mas del árbol de la ciencia del bien y del mal no comerás; porque el día que de él comieres, ciertamente morirás. — <BibleRef>Génesis 2:16-17</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography variant='h3'>
                        Libertad
                    </Typography>
                    <Typography>
                        No existe la libertad cuando hay solamente una opción. Para que el hombre fuera verdaderamente libre era necesario que tenga la opción de desobedecer y, por lo tanto, era necesario que exista el mal.
                    </Typography>
                    <Typography>
                        Según podemos ver, Dios hizo todo lo posible para respetar la libertad de Adán. En primer lugar le dió las opciones necesarias (obedecer o desobedecer). También vemos que no estuvo persiguiendo o acosando a los humanos sino que les dió el espacio necesario para tomar decisiones independientes. Y también vemos que no puso obstáculos adicionales, sino que ellos podían hacer efectivas sus decisiones con libertad.
                    </Typography>
                    {/* vicios de la libertad */}
                        {/* si obligan no hay libertad */}
                        {/* si tenes una sola opcion no hay .ibertad */}
                        {/* sustancias, emociones, etc */}
                    {/* opción */}
                    {/* no acoso */}
                    {/* no dificil */}
                    <Typography variant='h3'>
                        Desobediencia
                    </Typography>
                    <Typography>
                        Teniendo libertad para elegir y conociendo los términos del pacto, los seres humanos deciden comer de la fruta prohibida, haciéndose pasibles de las consecuencias por romper el pacto.
                    </Typography>
                    <Typography>
                        Hasta este momento los humanos confiaron en la definición de "bien y mal" que Dios proveía. Por ejemplo, no se habían dado cuenta que estaban desnudos, porque Dios no se los había explicado. Sin embargo, cuando comieron del árbol se hicieron independientes y ellos mismos se dieron cuenta que estaba mal. 
                    </Typography>
                    <Typography>
                        A partir de este momento sus errores ya no serían acciones inocentes, sino rebeldías, desobediencias, y actos de maldad. Ahora que entendían la realidad deberían hacerse responsables de sus actos.
                    </Typography>
                    {/* destituidos Romanos 3:23 */}
                    {/* el q peca es del diablo 1 Juan 3:8 */}
                    <Typography variant='h3'>
                        Autoridad de Satanás
                    </Typography>
                    <Typography>
                        Hasta este momento Satanás no tenía ninguna autoridad sobre la humanidad. No había ninguna acusación que pudiera hacer, ninguna maldición que le diera poder, y ninguna distancia entre el hombre y Dios. Pero quien peca es esclavo del pecado, y esto es precisamente lo que Adán hizo.
                    </Typography>
                    <Typography>
                        A partir de la desobediencia del ser humano Satanás los acusaría constantemente delante de Dios (<BibleRef>Ap. 12:10</BibleRef>), caería sobre ellos una maldición de Dios (<BibleRef>Gn 3:17-19</BibleRef>), y el hombre quedaría expulsado de la gloria de Dios (<BibleRef>Rom 3:23</BibleRef>).
                    </Typography>
                    <Typography>
                        De esta forma Satanás toma poder sobre la creación (<BibleRef>1 Jn 3:8</BibleRef>) y se convierte en el "dios de esta era" y "príncipe de este mundo" (<BibleRef>1 Jn 5:19, 2 Co 4:4, Ef 2:2, Jn 16:11, 12:31, 14:30, Lc 4:5-7</BibleRef>).
                    </Typography>
                    <Typography>
                        Así llegamos al día de hoy, donde el ser humano gobierna la tierra, pero Satanás gobierna al ser humano, con la clara excepción de aquellos que han sido quitados del poder de la oscuridad y trasladados al reino del Hijo de Dios (<BibleRef>Col 1:13</BibleRef>).
                    </Typography>
                    {/* <Typography>
                        El mundo está gobernado por Satanás (1 Jn 5:19, 2 Co 4:4, Ef 2:2, Jn 16:11, 12:31, 14:30, Lc 4:5-7) y todo lo que ofrece es malo (1 Juan 2:16, Tit 2:12, 1 Jn 2:17). Acta de los decretos Colosenses 2:14-15.
                    </Typography> */}
                    {/* <Notas footnotes={fn} /> */}
                    <Outline />
                    <Author />
                </Container>
            </Box>
        )
    }

// Frases paralelas dentro de un mismo ver (improbable):
//     -	La frase clave en deut. Es una repetición de la frase anterior
// -	En hebreos, el autor cierra el párrafo hablando de “lágrimas” por lo que vemos ahí el ejemplo claro de amargura. Puede que el ejemplo sea un paralelo de la statement?
// otro (dicho, solo guardo por frases)
// -	No se puede favorecer lo que no está por sobre lo que está. Es más probable que el autor quiera decir lo que escribe, y no lo que calla. Es más probable que use el ejemplo de Esaú para ilustrar las dos cualidades que menciona (fornicario, profano) en lugar de una que no menciona.


    const other_css: iBlogTestProps['other'] = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '0px',
                md: '0px',
            },
            textAlign: 'center',
        },
        badge: {
            textAlign: 'center',
            // fontFamily: 'Satoshi',
        },
        body: {
        },
        body1: {
            wordSpacing: '0.06em',
            // color: 'red !important'
        }
    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Spline Sans Variable' display_font='Satoshi' badge={badge} title={title} subtitle={subtitle} Body={<Body />} other={other_css} classes={{wrapper: 'SoftBlogColors'}} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}
