import Box from "@mui/material/Box";
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { PropsWithChildren } from "react";
import Typography from "@mui/material/Typography";
import AddCommentIcon from '@mui/icons-material/AddComment';

type iColorPalettes = 'BRG' | 'blue' | 'yellow_one' | 'gray_one' | 'green_one'

type iColorPalette = {
    bg: string;
    font: string;
};

export default function Aside(props: PropsWithChildren<{pallette?: iColorPalettes, icon?: string }>) {

    const { pallette, children, icon } = props

    const pallettes: { [K in iColorPalettes]: iColorPalette } = {
        'BRG': {
            bg: '#ECFEF2',
            font: '#055822',
        },
        'blue': {
            bg: '#B3C8FF91',
            font: '#1C243A',
        },
        'yellow_one': {
            bg: '#F9F1EF',
            font: '#4F73BF',
        },
        'gray_one': {
            bg: '#F3F4F0',
            font: '#5A4B25',
        },
        'green_one': {
            bg: '#EBFEF1',
            font: '#02501A',
        },
    };

    const chosen_pallette = pallettes[pallette || 'BRG']

    const iconProps = {
        sx: {
            color: chosen_pallette.font,
            pr: {
                us: '8px',
                md: '14px',
            },
            fontSize: '1.4em',
        }
    }

    return (
        <Typography component='span'>
            <Box component='aside'
                sx={{
                    '& .MuiTypography-root': {
                        fontSize: '0.80em',
                        color: chosen_pallette.font,
                        lineHeight: '1.7em',
                        fontVariationSettings: '"wght" 380'
                    },
                    // '& .MuiSvgIcon-root': {
                    //     color: '#055822',
                    //     pr: {
                    //         us: '8px',
                    //         md: '14px',
                    //     },
                    //     fontSize: '1.4em',
                    // },
                    display: 'flex',
                    flexDirection: 'row',
                    background: chosen_pallette.bg,
                    pl: {
                        us: '20px',
                        md: '28px',
                    },
                    pr: {
                        us: '12px',
                        md: '36px',
                    },
                    pt: '16px',
                    pb: '8px',
                    mb: '16px',
                    // mt: '8px',
                }}
            >
                { !icon && <PauseCircleIcon {...iconProps} /> }
                { icon === 'add comment' && <AddCommentIcon {...iconProps} /> }
                <Box>
                    { children }
                </Box>
            </Box>
        </Typography>
    )
}